import Script from 'next/script'

import { ENV } from '@/constants/env'

const GTM_JS_SCRIPT = `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}

gtag('consent', 'default', {
  'ad_storage': 'denied',
  'analytics_storage': 'denied'
});

(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${ENV.GTM_TRACKING_ID}');
`

const GTM_CONSENT_UDATE_JS_SCRIPT = `
gtag('consent', 'update', {
  'ad_storage': 'granted',
  'analytics_storage': 'granted'
});
`

export const ScriptGTM = () => {
  return (
    <Script
      id="gtm-init"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: GTM_JS_SCRIPT,
      }}
    />
  )
}

export const ScriptGTMConsentUpdate = () => {
  return (
    <Script
      id="consupd"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: GTM_CONSENT_UDATE_JS_SCRIPT,
      }}
    />
  )
}
