import { useMemo } from 'react'

import { useRouter } from 'next/router'

import { ENV } from '@/constants/env'

export const useCurrentUrl = () => {
  const router = useRouter()

  const currentUrl = useMemo(
    () =>
      ENV.SITE_URL +
      (router.locale === router.defaultLocale ? '' : `/${router.locale}`) +
      router.asPath,
    [router.locale, router.asPath, router.defaultLocale]
  )

  return { currentUrl }
}
