import localePaths from 'config/locale-paths'

const SITE_URL = process.env.NEXT_PUBLIC_FE_URL

export const getAlternateUrl = (path, targetLocale) => {
  const pathWithLocale = `/${targetLocale}${path}`
  const matchingReplacePath = localePaths.find((localePath) =>
    localePath.redirectPattern.test(pathWithLocale)
  )
  if (matchingReplacePath) {
    return `${SITE_URL}${pathWithLocale.replace(
      matchingReplacePath.redirectPattern,
      matchingReplacePath.redirectReplaceString
    )}`
  }
  return `${SITE_URL}${pathWithLocale}`
}

export const preparePathname = (pathname, locale) => {
  const pathnameWithLocale =
    pathname.startsWith('/en/') ||
    pathname.startsWith('/uk/') ||
    pathname.startsWith('/pl/')
      ? pathname
      : (locale ? `/${locale}` : '') + pathname
  const matchingRedirect = localePaths.find((localePath) =>
    localePath.redirectPattern.test(pathnameWithLocale)
  )
  const redirectedPathname = matchingRedirect
    ? pathnameWithLocale.replace(
        matchingRedirect.redirectPattern,
        matchingRedirect.redirectReplaceString
      )
    : pathnameWithLocale
  return redirectedPathname
}
