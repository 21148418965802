import Script from 'next/script'

import { ENV } from '@/constants/env'

const CLARITY_JS_SCRIPT = `(function(c,l,a,r,i,t,y){
  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
  t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "${ENV.CLARITY_ID}");`

export const ScriptClarity = () => {
  return (
    <Script
      id="clarity"
      dangerouslySetInnerHTML={{
        __html: CLARITY_JS_SCRIPT,
      }}
      strategy="lazyOnload"
    />
  )
}
