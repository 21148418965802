export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  UUID4: { input: any; output: any; }
};

export type AccountNameUpdateInput = {
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type AccountUpdateInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  treatmentsUuids?: InputMaybe<Array<Scalars['UUID4']['input']>>;
};

export type AccountWithProfile = {
  __typename?: 'AccountWithProfile';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type AddCategoryInput = {
  color: Scalars['String']['input'];
  headCategoryUuid: Scalars['UUID4']['input'];
  name: Scalars['String']['input'];
};

export type AddClientInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  sex?: InputMaybe<Sex>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  tagsUuids?: InputMaybe<Array<Scalars['UUID4']['input']>>;
};

export type AddEmployeeInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
};

export type AddHeadCategoryInput = {
  name: Scalars['String']['input'];
};

export type AddOnboardingCategoriesInput = {
  categories: Array<Scalars['String']['input']>;
  headCategoryName: Scalars['String']['input'];
};

export type AddTreatmentInput = {
  categoryUuid: Scalars['UUID4']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['Int']['input'];
  isExactPrice: Scalars['Boolean']['input'];
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Int']['input']>;
  priceFrom?: InputMaybe<Scalars['Int']['input']>;
  priceTo?: InputMaybe<Scalars['Int']['input']>;
};

export type Appointment = {
  __typename?: 'Appointment';
  appointmentUuid: Scalars['UUID4']['output'];
  clientName?: Maybe<Scalars['String']['output']>;
  clientUuid?: Maybe<Scalars['UUID4']['output']>;
  insertedAt: Scalars['DateTime']['output'];
  isSelfBooked: Scalars['Boolean']['output'];
  note?: Maybe<Scalars['String']['output']>;
  paymentMethodUuid?: Maybe<Scalars['UUID4']['output']>;
  status: AppointmentStatus;
  /** @deprecated Bulk appointments feature - use time range in treatments */
  timeRange: Timerange;
  totalPrice: Scalars['Int']['output'];
  /** @deprecated Bulk appointments feature - use treatments */
  treatment?: Maybe<AppointmentTreatment>;
  treatments: Array<AppointmentTreatment>;
};

export type AppointmentConnection = {
  __typename?: 'AppointmentConnection';
  count: Scalars['Int']['output'];
  edges: Array<AppointmentEdge>;
  pageInfo: PageInfo;
};

export type AppointmentEdge = {
  __typename?: 'AppointmentEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Appointment;
};

export type AppointmentFilters = {
  categoryUuid?: InputMaybe<Scalars['UUID4']['input']>;
  clientUuid?: InputMaybe<Scalars['UUID4']['input']>;
  employeeUuid?: InputMaybe<Scalars['UUID4']['input']>;
  status?: InputMaybe<AppointmentStatus>;
  statuses?: InputMaybe<Array<AppointmentStatus>>;
  timeRange?: InputMaybe<OptionalTimerangeInput>;
};

export enum AppointmentStatus {
  /** Canceled by employee */
  Canceled = 'CANCELED',
  /** Canceled by client */
  CanceledByClient = 'CANCELED_BY_CLIENT',
  /** Did not take place */
  Closed = 'CLOSED',
  /** Took place */
  Completed = 'COMPLETED',
  /** Requested by client */
  Requested = 'REQUESTED',
  /** Scheduled to take place */
  Scheduled = 'SCHEDULED'
}

export enum AppointmentSubscriptionAction {
  Create = 'CREATE',
  Update = 'UPDATE'
}

export type AppointmentTreatment = {
  __typename?: 'AppointmentTreatment';
  appointmentTreatmentUuid: Scalars['UUID4']['output'];
  categoryColor?: Maybe<Scalars['String']['output']>;
  employeeName?: Maybe<Scalars['String']['output']>;
  employeeUuid?: Maybe<Scalars['UUID4']['output']>;
  finalPrice: Scalars['Int']['output'];
  suggestedPrice: Scalars['Int']['output'];
  timeRange: Timerange;
  treatmentName: Scalars['String']['output'];
  treatmentUuid: Scalars['UUID4']['output'];
};

export type AppointmentTreatmentInput = {
  employeeUuid: Scalars['UUID4']['input'];
  treatmentUuid: Scalars['UUID4']['input'];
};

export type AppointmentTreatmentPrice = {
  __typename?: 'AppointmentTreatmentPrice';
  appointmentTreatmentUuid: Scalars['UUID4']['output'];
  name: Scalars['String']['output'];
  suggestedPrice: Scalars['Int']['output'];
};

export type AvailableBlock = {
  __typename?: 'AvailableBlock';
  from: Scalars['DateTime']['output'];
};

export type AvailableEquipment = {
  __typename?: 'AvailableEquipment';
  equipmentItemDescription?: Maybe<Scalars['String']['output']>;
  equipmentItemName: Scalars['String']['output'];
  equipmentItemUuid: Scalars['UUID4']['output'];
};

export type AvailableEquipmentsForTreatment = {
  __typename?: 'AvailableEquipmentsForTreatment';
  availableEquipments: Array<AvailableEquipment>;
  treatmentUuid: Scalars['UUID4']['output'];
};

export type BillingPortalSession = {
  __typename?: 'BillingPortalSession';
  customer: Scalars['String']['output'];
  id: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type BlockSlotInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  employeeUuid: Scalars['UUID4']['input'];
  timeRange: TimerangeInput;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type BlockedSlot = {
  __typename?: 'BlockedSlot';
  description?: Maybe<Scalars['String']['output']>;
  employeeName: Scalars['String']['output'];
  employeeUuid: Scalars['UUID4']['output'];
  insertedAt: Scalars['DateTime']['output'];
  timeRange: Timerange;
  title?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['UUID4']['output'];
};

export type BulkAvailabilityInput = {
  date: Scalars['DateTime']['input'];
  slug: Scalars['String']['input'];
  treatments: Array<BulkAvailabilityTreatmentInput>;
};

export type BulkAvailabilityTreatmentInput = {
  employeeUuid: Scalars['UUID4']['input'];
  treatmentUuid: Scalars['UUID4']['input'];
};

export type CalendarEmployee = {
  __typename?: 'CalendarEmployee';
  accountUuid: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  role: Role;
};

/** Appointment or blocked slot */
export type CalendarResult = Appointment | BlockedSlot;

export type Category = {
  __typename?: 'Category';
  color: Scalars['String']['output'];
  headCategoryUuid: Scalars['UUID4']['output'];
  insertedAt?: Maybe<Scalars['DateTime']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid: Scalars['UUID4']['output'];
};

export type CheckEquipmentsForTreatmentsAvailabilityInput = {
  timeRange: TimerangeInput;
  treatmentUuid: Scalars['UUID4']['input'];
};

export type Client = {
  __typename?: 'Client';
  city?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  imagesUrls: Array<Scalars['String']['output']>;
  insertedAt?: Maybe<Scalars['DateTime']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  sex?: Maybe<Sex>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  tags: Array<ClientTag>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid: Scalars['UUID4']['output'];
};

export type ClientAppointment = {
  __typename?: 'ClientAppointment';
  treatments: Array<AppointmentTreatment>;
};

export type ClientConnection = {
  __typename?: 'ClientConnection';
  count: Scalars['Int']['output'];
  edges: Array<ClientEdge>;
  pageInfo: PageInfo;
};

export type ClientEdge = {
  __typename?: 'ClientEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Client;
};

export type ClientFilters = {
  tagsUuids?: InputMaybe<Array<Scalars['UUID4']['input']>>;
};

export enum ClientSortBy {
  /** Inserted at */
  InsertedAt = 'INSERTED_AT',
  /** First name and last name */
  Name = 'NAME'
}

export type ClientTag = {
  __typename?: 'ClientTag';
  color: Scalars['String']['output'];
  name: Scalars['String']['output'];
  uuid: Scalars['UUID4']['output'];
};

export type ClientTagInput = {
  color: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CompanyBillingData = {
  __typename?: 'CompanyBillingData';
  city?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  nip?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  salonUuid: Scalars['UUID4']['output'];
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['UUID4']['output'];
};

export type CompanyBillingDataInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nip?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEquipmentItemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  treatmentsUuids: Array<Scalars['UUID4']['input']>;
};

export type CreateSalonInput = {
  name: Scalars['String']['input'];
};

export type CurrentSession = {
  __typename?: 'CurrentSession';
  accountUuid?: Maybe<Scalars['UUID4']['output']>;
  customSenderName?: Maybe<Scalars['Boolean']['output']>;
  email: Scalars['String']['output'];
  employeeLimit?: Maybe<Scalars['Int']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isOnboarded: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
  salonEmployees?: Maybe<Scalars['Int']['output']>;
  salonName?: Maybe<Scalars['String']['output']>;
  salonPlan?: Maybe<SubscriptionPlan>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  trialUsed?: Maybe<Scalars['Boolean']['output']>;
};

export type Employee = {
  __typename?: 'Employee';
  accountUuid: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  isAvailableForAppointments: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  role: Role;
  treatments: Array<Treatment>;
  workSchedule?: Maybe<Scalars['JSON']['output']>;
};

export type EmployeeCalendarResult = {
  __typename?: 'EmployeeCalendarResult';
  employee: CalendarEmployee;
  entries: Array<CalendarResult>;
};

export type EmployeeConnection = {
  __typename?: 'EmployeeConnection';
  count: Scalars['Int']['output'];
  edges: Array<EmployeeEdge>;
  pageInfo: PageInfo;
};

export type EmployeeEdge = {
  __typename?: 'EmployeeEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Employee;
};

export type EquipmentItem = {
  __typename?: 'EquipmentItem';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  treatments: Array<EquipmentItemTreatment>;
  uuid: Scalars['UUID4']['output'];
};

export type EquipmentItemConnection = {
  __typename?: 'EquipmentItemConnection';
  count: Scalars['Int']['output'];
  edges: Array<EquipmentItemEdge>;
  pageInfo: PageInfo;
};

export type EquipmentItemEdge = {
  __typename?: 'EquipmentItemEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: EquipmentItem;
};

export type EquipmentItemTreatment = {
  __typename?: 'EquipmentItemTreatment';
  treatmentName: Scalars['String']['output'];
  treatmentUuid: Scalars['UUID4']['output'];
};

export type ExampleCategory = {
  __typename?: 'ExampleCategory';
  categories: Array<Scalars['String']['output']>;
  headCategoryName: Scalars['String']['output'];
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  enabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type Feedback = {
  __typename?: 'Feedback';
  message: Scalars['String']['output'];
  subject: Scalars['String']['output'];
};

export type FeedbackInput = {
  message: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type FinalizeAppointmentInput = {
  appointmentUuid: Scalars['UUID4']['input'];
  status: AppointmentStatus;
  totalPrice?: InputMaybe<Scalars['Int']['input']>;
};

export type FinalizeBulkAppointmentInput = {
  appointmentUuid: Scalars['UUID4']['input'];
  paymentMethodUuid?: InputMaybe<Scalars['UUID4']['input']>;
  status: AppointmentStatus;
  treatments: Array<FinalizeBulkTreatmentInput>;
};

export type FinalizeBulkTreatmentInput = {
  appointmentTreatmentUuid: Scalars['UUID4']['input'];
  finalPrice: Scalars['Int']['input'];
};

export type GeneralNumbers = {
  __typename?: 'GeneralNumbers';
  canceledAppointments: Scalars['Int']['output'];
  finalizedAppointments: Scalars['Int']['output'];
  newClients: Scalars['Int']['output'];
  totalRevenue: Scalars['Int']['output'];
};

export type HeadCategory = {
  __typename?: 'HeadCategory';
  categories?: Maybe<Array<Maybe<Category>>>;
  insertedAt?: Maybe<Scalars['DateTime']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  salonUuid: Scalars['UUID4']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid: Scalars['UUID4']['output'];
};

export type HeadCategoryConnection = {
  __typename?: 'HeadCategoryConnection';
  count: Scalars['Int']['output'];
  edges: Array<HeadCategoryEdge>;
  pageInfo: PageInfo;
};

export type HeadCategoryEdge = {
  __typename?: 'HeadCategoryEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: HeadCategory;
};

export type IncomeNumbers = {
  __typename?: 'IncomeNumbers';
  appointmentsCount: Scalars['Int']['output'];
  date: Scalars['DateTime']['output'];
  income: Scalars['Int']['output'];
};

export type MakeOrderInput = {
  orderItems: Scalars['JSON']['input'];
  paymentMode: Scalars['String']['input'];
};

export type MakePaymentInput = {
  accountUuid: Scalars['UUID4']['input'];
  orderItems: Scalars['JSON']['input'];
  orderUuid: Scalars['UUID4']['input'];
  paymentMode: PaymentMode;
};

export enum MaxLeadTimeOptionsInDays {
  /** 7 days */
  Days_7 = 'DAYS_7',
  /** 14 days */
  Days_14 = 'DAYS_14',
  /** 1 month */
  Days_30 = 'DAYS_30',
  /** 2 months */
  Days_60 = 'DAYS_60',
  /** 3 months */
  Days_90 = 'DAYS_90',
  /** 6 months */
  Days_180 = 'DAYS_180',
  /** 1 year */
  Days_365 = 'DAYS_365',
  /** 2 years */
  Days_730 = 'DAYS_730'
}

export enum MinLeadTimeOptionsInMinutes {
  /** 15 minutes */
  Minutes_15 = 'MINUTES_15',
  /** 30 minutes */
  Minutes_30 = 'MINUTES_30',
  /** 1 hour */
  Minutes_60 = 'MINUTES_60',
  /** 2 hours */
  Minutes_120 = 'MINUTES_120',
  /** 3 hours */
  Minutes_180 = 'MINUTES_180',
  /** 6 hours */
  Minutes_360 = 'MINUTES_360',
  /** 12 hours */
  Minutes_720 = 'MINUTES_720',
  /** 1 day */
  Minutes_1440 = 'MINUTES_1440',
  /** 2 days */
  Minutes_2880 = 'MINUTES_2880',
  /** 3 days */
  Minutes_4320 = 'MINUTES_4320',
  /** 5 days */
  Minutes_7200 = 'MINUTES_7200',
  /** 7 days */
  Minutes_10080 = 'MINUTES_10080',
  /** 10 days */
  Minutes_14400 = 'MINUTES_14400',
  /** 14 days */
  Minutes_20160 = 'MINUTES_20160'
}

export type OptionalTimerangeInput = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Order = {
  __typename?: 'Order';
  accountEmail: Scalars['String']['output'];
  accountUuid: Scalars['UUID4']['output'];
  orderItems?: Maybe<Scalars['JSON']['output']>;
  paymentMode?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['UUID4']['output'];
};

export type OrderEvent = {
  __typename?: 'OrderEvent';
  accountUuid?: Maybe<Scalars['UUID4']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  orderItems?: Maybe<Scalars['JSON']['output']>;
  orderUuid?: Maybe<Scalars['UUID4']['output']>;
  uuid: Scalars['UUID4']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum PaymentMode {
  /** payment mode */
  Payment = 'PAYMENT',
  /** subscription if at least one item in order is subscription */
  Subscription = 'SUBSCRIPTION'
}

export type PersonalBillingData = {
  __typename?: 'PersonalBillingData';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  salonUuid: Scalars['UUID4']['output'];
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['UUID4']['output'];
};

export type PersonalBillingDataInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum PhoneType {
  /** Mobile */
  Mobile = 'MOBILE',
  /** Phone */
  Phone = 'PHONE'
}

export type Price = {
  __typename?: 'Price';
  billingScheme?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  priceDecimal?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Product = {
  __typename?: 'Product';
  name?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Price>;
  priceId?: Maybe<Scalars['String']['output']>;
  stripeId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum ProductType {
  Product = 'PRODUCT',
  Subscription = 'SUBSCRIPTION'
}

export type PublicCategory = {
  __typename?: 'PublicCategory';
  categoryColor: Scalars['String']['output'];
  categoryName: Scalars['String']['output'];
  categoryUuid: Scalars['UUID4']['output'];
  treatments?: Maybe<Array<Maybe<PublicTreatment>>>;
};

export type PublicHeadCategory = {
  __typename?: 'PublicHeadCategory';
  categories?: Maybe<Array<Maybe<PublicCategory>>>;
  headCategoryName: Scalars['String']['output'];
  headCategoryUuid: Scalars['UUID4']['output'];
};

export type PublicTreatment = {
  __typename?: 'PublicTreatment';
  treatmentDescription?: Maybe<Scalars['String']['output']>;
  treatmentDuration: Scalars['Int']['output'];
  treatmentIsExactPrice: Scalars['Boolean']['output'];
  treatmentName: Scalars['String']['output'];
  treatmentPrice?: Maybe<Scalars['Int']['output']>;
  treatmentPriceFrom?: Maybe<Scalars['Int']['output']>;
  treatmentPriceTo?: Maybe<Scalars['Int']['output']>;
  treatmentUuid: Scalars['UUID4']['output'];
};

export type ReferralScore = {
  __typename?: 'ReferralScore';
  name: Scalars['String']['output'];
  score: Scalars['Int']['output'];
};

export enum Role {
  /** Employee */
  Employee = 'EMPLOYEE',
  /** Owner */
  Owner = 'OWNER',
  /** Receptionist */
  Receptionist = 'RECEPTIONIST'
}

export type RootMutationType = {
  __typename?: 'RootMutationType';
  /** Adds category to head category */
  addCategory: Category;
  /** Adds client to salon */
  addClient: Client;
  /** Adds head category to salon */
  addHeadCategory: HeadCategory;
  addOnboardingCategories: Scalars['String']['output'];
  addSalonEmployee: Employee;
  /** Adds treatment to category */
  addTreatment: Treatment;
  /** Approves requested appointment */
  approveAppointmentRequest: Appointment;
  blockSlot: BlockedSlot;
  /** Cancels an appointment */
  cancelAppointment: Appointment;
  changeSubscriptionPlan: Scalars['String']['output'];
  /** Client cancels an appointment - without auth */
  clientCancelAppointment: Appointment;
  /** Client schedules an appointment - without auth */
  clientScheduleAppointment: Appointment;
  createBillingPortalSession: BillingPortalSession;
  /** Creates client tag */
  createClientTag: ClientTag;
  createCompanyBillingData: CompanyBillingData;
  /** Creates equipment item in salon */
  createEquipmentItem: EquipmentItem;
  createPersonalBillingData: PersonalBillingData;
  createSalon: Salon;
  deleteBlockedSlot: Scalars['String']['output'];
  /** Deletes given category */
  deleteCategory: Category;
  /** Deletes given client */
  deleteClient: Client;
  /** Deletes client tag */
  deleteClientTag: Scalars['String']['output'];
  /** Deetes given equipment item */
  deleteEquipmentItem: Scalars['String']['output'];
  /** Deletes given head category */
  deleteHeadCategory: HeadCategory;
  deleteSalonEmployee: Scalars['String']['output'];
  /** Deletes given treatment */
  deleteTreatment: Treatment;
  /** Finalizes an appointment */
  finalizeAppointment: Appointment;
  /** Finalizes a a bulk appointment */
  finalizeBulkAppointment: Appointment;
  finishSalonOnboarding: Salon;
  makeOrder: Order;
  /** Returns payment session */
  makePayment: Session;
  /**
   * Schedules an appointment with treatment
   * @deprecated Bulk appointments feature - use scheduleBulkAppointment
   */
  scheduleAppointment: Appointment;
  /** Schedules a bulk appointment with treatment */
  scheduleBulkAppointment: Appointment;
  /** Send feedback */
  sendFeedback: Feedback;
  /** Send sms to clients */
  sendSmsToClients: Scalars['String']['output'];
  /** Sends verification email for self booking appointment - without auth */
  sendVerificationSms: Scalars['Boolean']['output'];
  setSalonDefaultPaymentMethod: SalonPaymentMethod;
  /** Undos appointment finalization */
  undoAppointmentFinalization: Appointment;
  unsetSalonDefaultPaymentMethod: Array<SalonPaymentMethod>;
  updateAccount: AccountWithProfile;
  updateAccountName: AccountWithProfile;
  /**
   * Updates an appointment
   * @deprecated Bulk appointments feature - use updateBulkAppointment
   */
  updateAppointment: Appointment;
  updateBlockedSlot: BlockedSlot;
  /** Updates an appointment */
  updateBulkAppointment: Appointment;
  /** Updates positions of categories */
  updateCategoriesPositions: Scalars['String']['output'];
  /** Updates given category */
  updateCategory: Category;
  /** Updates given client */
  updateClient: Client;
  /** Updates client tag */
  updateClientTag: ClientTag;
  updateCompanyBillingData: CompanyBillingData;
  /** Updates positions of employees */
  updateEmployeesPositions: Scalars['String']['output'];
  /** Updates given equipment item */
  updateEquipmentItem: EquipmentItem;
  /** Updates positions of head categories */
  updateHeadCategoriesPositions: Scalars['String']['output'];
  /** Updates given head category */
  updateHeadCategory: HeadCategory;
  updatePersonalBillingData: PersonalBillingData;
  updateSalon: Salon;
  updateSalonEmployee: Employee;
  updateSalonOpeningHours: SalonOpeningHours;
  updateSalonPaymentMethods: Array<SalonPaymentMethod>;
  updateSalonPaymentMethodsPositions: Array<SalonPaymentMethod>;
  updateSalonSenderName: SalonSmsDetails;
  updateSalonSmsDetails: SalonSmsDetails;
  updateSalonSmsTemplate: Array<SalonSmsTemplate>;
  /** Updates given treatment */
  updateTreatment: Treatment;
  /** Updates positions of given treatments */
  updateTreatmentsPositions: Scalars['String']['output'];
};


export type RootMutationTypeAddCategoryArgs = {
  data: AddCategoryInput;
};


export type RootMutationTypeAddClientArgs = {
  data: AddClientInput;
};


export type RootMutationTypeAddHeadCategoryArgs = {
  data: AddHeadCategoryInput;
};


export type RootMutationTypeAddOnboardingCategoriesArgs = {
  data: Array<AddOnboardingCategoriesInput>;
};


export type RootMutationTypeAddSalonEmployeeArgs = {
  data: AddEmployeeInput;
};


export type RootMutationTypeAddTreatmentArgs = {
  data: AddTreatmentInput;
};


export type RootMutationTypeApproveAppointmentRequestArgs = {
  data: UpdateBulkAppointmentInput;
};


export type RootMutationTypeBlockSlotArgs = {
  data: BlockSlotInput;
};


export type RootMutationTypeCancelAppointmentArgs = {
  appointmentUuid: Scalars['UUID4']['input'];
  sendNotification: Scalars['Boolean']['input'];
};


export type RootMutationTypeChangeSubscriptionPlanArgs = {
  subscriptionPriceId: Scalars['String']['input'];
};


export type RootMutationTypeClientCancelAppointmentArgs = {
  token: Scalars['String']['input'];
};


export type RootMutationTypeClientScheduleAppointmentArgs = {
  data: ScheduleSelfBookingAppointmentInput;
};


export type RootMutationTypeCreateBillingPortalSessionArgs = {
  stripeCustomerId: Scalars['String']['input'];
};


export type RootMutationTypeCreateClientTagArgs = {
  data: ClientTagInput;
};


export type RootMutationTypeCreateCompanyBillingDataArgs = {
  data: CompanyBillingDataInput;
};


export type RootMutationTypeCreateEquipmentItemArgs = {
  data: CreateEquipmentItemInput;
};


export type RootMutationTypeCreatePersonalBillingDataArgs = {
  data: PersonalBillingDataInput;
};


export type RootMutationTypeCreateSalonArgs = {
  data: CreateSalonInput;
};


export type RootMutationTypeDeleteBlockedSlotArgs = {
  uuid: Scalars['UUID4']['input'];
};


export type RootMutationTypeDeleteCategoryArgs = {
  categoryUuid: Scalars['UUID4']['input'];
};


export type RootMutationTypeDeleteClientArgs = {
  clientUuid: Scalars['UUID4']['input'];
};


export type RootMutationTypeDeleteClientTagArgs = {
  tagUuid: Scalars['UUID4']['input'];
};


export type RootMutationTypeDeleteEquipmentItemArgs = {
  equipmentItemUuid: Scalars['UUID4']['input'];
};


export type RootMutationTypeDeleteHeadCategoryArgs = {
  categoryUuid: Scalars['UUID4']['input'];
};


export type RootMutationTypeDeleteSalonEmployeeArgs = {
  accountUuid: Scalars['UUID4']['input'];
};


export type RootMutationTypeDeleteTreatmentArgs = {
  treatmentUuid: Scalars['UUID4']['input'];
};


export type RootMutationTypeFinalizeAppointmentArgs = {
  data: FinalizeAppointmentInput;
};


export type RootMutationTypeFinalizeBulkAppointmentArgs = {
  data: FinalizeBulkAppointmentInput;
};


export type RootMutationTypeMakeOrderArgs = {
  data: MakeOrderInput;
};


export type RootMutationTypeMakePaymentArgs = {
  data: MakePaymentInput;
};


export type RootMutationTypeScheduleAppointmentArgs = {
  data: ScheduleAppointmentInput;
};


export type RootMutationTypeScheduleBulkAppointmentArgs = {
  data: ScheduleBulkAppointmentInput;
};


export type RootMutationTypeSendFeedbackArgs = {
  data: FeedbackInput;
};


export type RootMutationTypeSendSmsToClientsArgs = {
  clientsUuids: Array<Scalars['UUID4']['input']>;
  message: Scalars['String']['input'];
};


export type RootMutationTypeSendVerificationSmsArgs = {
  phone: Scalars['String']['input'];
  slug: Scalars['String']['input'];
};


export type RootMutationTypeSetSalonDefaultPaymentMethodArgs = {
  data: Scalars['UUID4']['input'];
};


export type RootMutationTypeUndoAppointmentFinalizationArgs = {
  appointmentUuid: Scalars['UUID4']['input'];
};


export type RootMutationTypeUpdateAccountArgs = {
  data: AccountUpdateInput;
};


export type RootMutationTypeUpdateAccountNameArgs = {
  data: AccountNameUpdateInput;
};


export type RootMutationTypeUpdateAppointmentArgs = {
  data: UpdateAppointmentInput;
};


export type RootMutationTypeUpdateBlockedSlotArgs = {
  data: UpdateBlockedSlotInput;
};


export type RootMutationTypeUpdateBulkAppointmentArgs = {
  data: UpdateBulkAppointmentInput;
};


export type RootMutationTypeUpdateCategoriesPositionsArgs = {
  data: UpdateCategoriesPositionsInput;
};


export type RootMutationTypeUpdateCategoryArgs = {
  categoryUuid: Scalars['UUID4']['input'];
  data: UpdateCategoryInput;
};


export type RootMutationTypeUpdateClientArgs = {
  clientUuid: Scalars['UUID4']['input'];
  data: UpdateClientInput;
};


export type RootMutationTypeUpdateClientTagArgs = {
  data: ClientTagInput;
  tagUuid: Scalars['UUID4']['input'];
};


export type RootMutationTypeUpdateCompanyBillingDataArgs = {
  data: CompanyBillingDataInput;
};


export type RootMutationTypeUpdateEmployeesPositionsArgs = {
  data: UpdateEmployeesPositionsInput;
};


export type RootMutationTypeUpdateEquipmentItemArgs = {
  data: UpdateEquipmentItemInput;
  equipmentItemUuid: Scalars['UUID4']['input'];
};


export type RootMutationTypeUpdateHeadCategoriesPositionsArgs = {
  data: UpdateHeadCategoriesPositionsInput;
};


export type RootMutationTypeUpdateHeadCategoryArgs = {
  categoryUuid: Scalars['UUID4']['input'];
  data: UpdateHeadCategoryInput;
};


export type RootMutationTypeUpdatePersonalBillingDataArgs = {
  data: PersonalBillingDataInput;
};


export type RootMutationTypeUpdateSalonArgs = {
  data: UpdateSalonInput;
};


export type RootMutationTypeUpdateSalonEmployeeArgs = {
  accountUuid: Scalars['UUID4']['input'];
  data: UpdateEmployeeInput;
};


export type RootMutationTypeUpdateSalonOpeningHoursArgs = {
  data: UpdateSalonOpeningHoursInput;
};


export type RootMutationTypeUpdateSalonPaymentMethodsArgs = {
  data: Array<SalonPaymentMethodInput>;
};


export type RootMutationTypeUpdateSalonPaymentMethodsPositionsArgs = {
  data: Array<Scalars['UUID4']['input']>;
};


export type RootMutationTypeUpdateSalonSenderNameArgs = {
  senderName: Scalars['String']['input'];
};


export type RootMutationTypeUpdateSalonSmsDetailsArgs = {
  data: UpdateSalonSmsDetailsInput;
};


export type RootMutationTypeUpdateSalonSmsTemplateArgs = {
  data: UpdateSmsTemplateInput;
};


export type RootMutationTypeUpdateTreatmentArgs = {
  data: UpdateTreatmentInput;
  treatmentUuid: Scalars['UUID4']['input'];
};


export type RootMutationTypeUpdateTreatmentsPositionsArgs = {
  data: UpdateTreatmentsPositionsInput;
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  checkEquipmentsForTreatmentsAvailability: Array<AvailableEquipmentsForTreatment>;
  /** Fetches logged user details */
  currentSession: CurrentSession;
  featureFlag: FeatureFlag;
  featureFlags: Array<FeatureFlag>;
  fetchAllPublicTreatments: SearchTreatmentConnection;
  fetchAllTreatments: SearchTreatmentConnection;
  fetchAppointment: Appointment;
  fetchAppointmentCancelLink?: Maybe<Scalars['String']['output']>;
  fetchAppointments: Array<Appointment>;
  fetchBulkAppointments: Array<Appointment>;
  fetchBulkAppointmentsPaginated: AppointmentConnection;
  fetchBulkAvailableSlots: Array<AvailableBlock>;
  fetchCalendar: Array<CalendarResult>;
  fetchCalendarByEmployee: Array<EmployeeCalendarResult>;
  fetchCategory: Category;
  fetchChosenTreatments: Array<SearchTreatment>;
  fetchClient: Client;
  fetchClientAppointment: ClientAppointment;
  fetchEmployee: Employee;
  /** Fetches salon employees */
  fetchEmployees: EmployeeConnection;
  fetchExampleCategories: Array<ExampleCategory>;
  fetchGeneralNumbers: GeneralNumbers;
  fetchHeadCategories: HeadCategoryConnection;
  fetchHeadCategory: HeadCategory;
  fetchIncomeNumbers: Array<IncomeNumbers>;
  fetchOrder: Order;
  fetchOrderEvents: Array<OrderEvent>;
  fetchOrders: Array<Order>;
  fetchPrice: Price;
  fetchProduct: Product;
  fetchProductByPrice: Product;
  fetchProductsWithPrices: Array<Product>;
  fetchProductsWithPricesByType: Array<Product>;
  fetchPublicEmployees: Array<Employee>;
  fetchPublicSalon: Salon;
  fetchPublicSalonOpeningHours: SalonOpeningHours;
  fetchReferralScore: ReferralScore;
  fetchRemainingAppointments?: Maybe<Scalars['Int']['output']>;
  fetchSalon: Salon;
  fetchSalonClients: ClientConnection;
  fetchSalonCompanyBillingData: CompanyBillingData;
  fetchSalonEquipmentItems: EquipmentItemConnection;
  fetchSalonOpeningHours: SalonOpeningHours;
  fetchSalonPaymentMethod: SalonPaymentMethod;
  fetchSalonPaymentMethods?: Maybe<Array<SalonPaymentMethod>>;
  fetchSalonPersonalBillingData: PersonalBillingData;
  fetchSalonPublicTreatments: Array<PublicHeadCategory>;
  fetchSalonSmsDetails: SalonSmsDetails;
  fetchSalonSmsTemplate: SalonSmsTemplate;
  fetchSalonSmsTemplates: Array<SalonSmsTemplate>;
  fetchSalonTags: Array<ClientTag>;
  fetchSalonsSlugs: Array<Scalars['String']['output']>;
  fetchSentSms: SmsConnection;
  fetchSmsLimit: Scalars['Int']['output'];
  fetchSuggestedAppointmentTreatmentsPrices: Array<AppointmentTreatmentPrice>;
  fetchTreatment: Treatment;
  fetchTreatments: TreatmentConnection;
  getEquipmentItem: EquipmentItem;
  publicSearchTreatment: SearchTreatment;
  searchTreatment: SearchTreatment;
};


export type RootQueryTypeCheckEquipmentsForTreatmentsAvailabilityArgs = {
  data: Array<CheckEquipmentsForTreatmentsAvailabilityInput>;
};


export type RootQueryTypeFeatureFlagArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeFeatureFlagsArgs = {
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type RootQueryTypeFetchAllPublicTreatmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeFetchAllTreatmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeFetchAppointmentArgs = {
  appointmentUuid: Scalars['UUID4']['input'];
};


export type RootQueryTypeFetchAppointmentCancelLinkArgs = {
  appointmentUuid: Scalars['UUID4']['input'];
};


export type RootQueryTypeFetchAppointmentsArgs = {
  filters: AppointmentFilters;
};


export type RootQueryTypeFetchBulkAppointmentsArgs = {
  filters: AppointmentFilters;
};


export type RootQueryTypeFetchBulkAppointmentsPaginatedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters: AppointmentFilters;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortByDatetime?: InputMaybe<SortOrder>;
};


export type RootQueryTypeFetchBulkAvailableSlotsArgs = {
  data: BulkAvailabilityInput;
};


export type RootQueryTypeFetchCalendarArgs = {
  filters: AppointmentFilters;
};


export type RootQueryTypeFetchCalendarByEmployeeArgs = {
  filters: AppointmentFilters;
};


export type RootQueryTypeFetchCategoryArgs = {
  categoryUuid: Scalars['UUID4']['input'];
};


export type RootQueryTypeFetchChosenTreatmentsArgs = {
  uuids: Array<Scalars['UUID4']['input']>;
};


export type RootQueryTypeFetchClientArgs = {
  clientUuid?: InputMaybe<Scalars['UUID4']['input']>;
};


export type RootQueryTypeFetchClientAppointmentArgs = {
  token: Scalars['String']['input'];
};


export type RootQueryTypeFetchEmployeeArgs = {
  accountUuid: Scalars['UUID4']['input'];
};


export type RootQueryTypeFetchEmployeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeFetchGeneralNumbersArgs = {
  timeRange: TimerangeInput;
};


export type RootQueryTypeFetchHeadCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeFetchHeadCategoryArgs = {
  categoryUuid: Scalars['UUID4']['input'];
};


export type RootQueryTypeFetchIncomeNumbersArgs = {
  displayFormat: StatisticsDisplayFormat;
  period: StatisticsPeriod;
};


export type RootQueryTypeFetchOrderArgs = {
  uuid: Scalars['UUID4']['input'];
};


export type RootQueryTypeFetchOrderEventsArgs = {
  uuid: Scalars['UUID4']['input'];
};


export type RootQueryTypeFetchPriceArgs = {
  id: Scalars['String']['input'];
};


export type RootQueryTypeFetchProductArgs = {
  id: Scalars['String']['input'];
};


export type RootQueryTypeFetchProductByPriceArgs = {
  priceId: Scalars['String']['input'];
};


export type RootQueryTypeFetchProductsWithPricesByTypeArgs = {
  type: ProductType;
};


export type RootQueryTypeFetchPublicEmployeesArgs = {
  slug: Scalars['String']['input'];
  treatmentUuid?: InputMaybe<Scalars['UUID4']['input']>;
};


export type RootQueryTypeFetchPublicSalonArgs = {
  slug: Scalars['String']['input'];
};


export type RootQueryTypeFetchPublicSalonOpeningHoursArgs = {
  slug: Scalars['String']['input'];
};


export type RootQueryTypeFetchReferralScoreArgs = {
  referralId: Scalars['String']['input'];
};


export type RootQueryTypeFetchSalonClientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ClientFilters>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<ClientSortBy>;
  sortOrder?: InputMaybe<SortOrder>;
};


export type RootQueryTypeFetchSalonEquipmentItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeFetchSalonPaymentMethodArgs = {
  data: Scalars['UUID4']['input'];
};


export type RootQueryTypeFetchSalonPublicTreatmentsArgs = {
  slug: Scalars['String']['input'];
};


export type RootQueryTypeFetchSalonSmsTemplateArgs = {
  type: Scalars['String']['input'];
};


export type RootQueryTypeFetchSentSmsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  clientUuid?: InputMaybe<Scalars['UUID4']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeFetchSuggestedAppointmentTreatmentsPricesArgs = {
  appointmentUuid: Scalars['UUID4']['input'];
};


export type RootQueryTypeFetchTreatmentArgs = {
  treatmentUuid: Scalars['UUID4']['input'];
};


export type RootQueryTypeFetchTreatmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  categoryUuid: Scalars['UUID4']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type RootQueryTypeGetEquipmentItemArgs = {
  equipmentItemUuid?: InputMaybe<Scalars['UUID4']['input']>;
};


export type RootQueryTypePublicSearchTreatmentArgs = {
  treatmentUuid: Scalars['UUID4']['input'];
};


export type RootQueryTypeSearchTreatmentArgs = {
  treatmentUuid: Scalars['UUID4']['input'];
};

export type RootSubscriptionType = {
  __typename?: 'RootSubscriptionType';
  appointmentCreatedOrUpdated?: Maybe<SubscriptionAppointment>;
  smsLimitUpdated: Scalars['Int']['output'];
};


export type RootSubscriptionTypeAppointmentCreatedOrUpdatedArgs = {
  filters: AppointmentFilters;
};

export type Salon = {
  __typename?: 'Salon';
  backgroundImageUrl?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  isOnboarded?: Maybe<Scalars['Boolean']['output']>;
  isPublic: Scalars['Boolean']['output'];
  links?: Maybe<Scalars['JSON']['output']>;
  logoImageUrl?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  phoneType?: Maybe<PhoneType>;
  postalCode?: Maybe<Scalars['String']['output']>;
  salonUuid: Scalars['UUID4']['output'];
  selfBookingAutomaticalSlotApproval: Scalars['Boolean']['output'];
  selfBookingAutomaticalSlotApprovalSms: Scalars['Boolean']['output'];
  selfBookingMaxLeadTime: MaxLeadTimeOptionsInDays;
  selfBookingMinLeadTime: MinLeadTimeOptionsInMinutes;
  selfBookingSlotDuration: Scalars['Int']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  socials?: Maybe<Scalars['JSON']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
};

export type SalonOpeningHours = {
  __typename?: 'SalonOpeningHours';
  regular?: Maybe<Scalars['JSON']['output']>;
  special?: Maybe<Scalars['JSON']['output']>;
};

export type SalonPaymentMethod = {
  __typename?: 'SalonPaymentMethod';
  default: Scalars['Boolean']['output'];
  enabled: Scalars['Boolean']['output'];
  name: SalonPaymentMethods;
  salonUuid: Scalars['UUID4']['output'];
  uuid: Scalars['UUID4']['output'];
};

export type SalonPaymentMethodInput = {
  enabled: Scalars['Boolean']['input'];
  uuid: Scalars['UUID4']['input'];
};

export enum SalonPaymentMethods {
  /** Bank Transfer */
  BankTransfer = 'BANK_TRANSFER',
  /** BLIK */
  Blik = 'BLIK',
  /** Cash */
  Cash = 'CASH',
  /** Credit Card */
  CreditCard = 'CREDIT_CARD',
  /** Voucher */
  Voucher = 'VOUCHER'
}

export type SalonSmsDetails = {
  __typename?: 'SalonSmsDetails';
  sendReminders: Scalars['Boolean']['output'];
  senderName: Scalars['String']['output'];
  senderNameApproved: Scalars['Boolean']['output'];
};

export type SalonSmsTemplate = {
  __typename?: 'SalonSmsTemplate';
  description?: Maybe<Scalars['String']['output']>;
  salonUuid: Scalars['UUID4']['output'];
  title: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['UUID4']['output'];
};

export type ScheduleAppointmentInput = {
  clientUuid?: InputMaybe<Scalars['UUID4']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  sendNotification: Scalars['Boolean']['input'];
  timeRange: TimerangeInput;
  treatment: AppointmentTreatmentInput;
};

export type ScheduleBulkAppointmentInput = {
  clientUuid?: InputMaybe<Scalars['UUID4']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  sendNotification: Scalars['Boolean']['input'];
  treatments: Array<ScheduleBulkAppointmentTreatmentInput>;
};

export type ScheduleBulkAppointmentTreatmentInput = {
  employeeUuid: Scalars['UUID4']['input'];
  timeRange: TimerangeInput;
  treatmentUuid: Scalars['UUID4']['input'];
};

export type ScheduleSelfBookingAppointmentInput = {
  clientFirstName: Scalars['String']['input'];
  clientLastName: Scalars['String']['input'];
  clientPhone: Scalars['String']['input'];
  salonUuid: Scalars['UUID4']['input'];
  treatments: Array<ScheduleSelfBookingAppointmentTreatmentInput>;
  verificationToken: Scalars['String']['input'];
};

export type ScheduleSelfBookingAppointmentTreatmentInput = {
  employeeUuid: Scalars['UUID4']['input'];
  from: Scalars['DateTime']['input'];
  treatmentUuid: Scalars['UUID4']['input'];
};

export type SearchTreatment = {
  __typename?: 'SearchTreatment';
  categoryColor: Scalars['String']['output'];
  categoryName: Scalars['String']['output'];
  categoryUuid: Scalars['UUID4']['output'];
  treatmentDescription?: Maybe<Scalars['String']['output']>;
  treatmentDuration: Scalars['Int']['output'];
  treatmentIsExactPrice?: Maybe<Scalars['Boolean']['output']>;
  treatmentName: Scalars['String']['output'];
  treatmentPrice?: Maybe<Scalars['Int']['output']>;
  treatmentPriceFrom?: Maybe<Scalars['Int']['output']>;
  treatmentPriceTo?: Maybe<Scalars['Int']['output']>;
  treatmentUuid: Scalars['UUID4']['output'];
};

export type SearchTreatmentConnection = {
  __typename?: 'SearchTreatmentConnection';
  count: Scalars['Int']['output'];
  edges: Array<SearchTreatmentEdge>;
  pageInfo: PageInfo;
};

export type SearchTreatmentEdge = {
  __typename?: 'SearchTreatmentEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: SearchTreatment;
};

export type Session = {
  __typename?: 'Session';
  amountSubtotal: Scalars['Int']['output'];
  amountTotal: Scalars['Int']['output'];
  cancelUrl: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  metadata: Scalars['JSON']['output'];
  mode: Scalars['String']['output'];
  paymentStatus: Scalars['String']['output'];
  status: Scalars['String']['output'];
  successUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum Sex {
  /** Female */
  Female = 'FEMALE',
  /** Male */
  Male = 'MALE'
}

export type Sms = {
  __typename?: 'Sms';
  clientUuid: Scalars['UUID4']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  recipient?: Maybe<Scalars['String']['output']>;
  salonUuid: Scalars['UUID4']['output'];
  senderName?: Maybe<Scalars['String']['output']>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
  status: SmsStatus;
  uuid: Scalars['UUID4']['output'];
};

export type SmsConnection = {
  __typename?: 'SmsConnection';
  count: Scalars['Int']['output'];
  edges: Array<SmsEdge>;
  pageInfo: PageInfo;
};

export type SmsEdge = {
  __typename?: 'SmsEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Sms;
};

export enum SmsStatus {
  Discarded = 'DISCARDED',
  Sent = 'SENT'
}

export enum SortOrder {
  /** Ascending */
  Asc = 'ASC',
  /** Descending */
  Desc = 'DESC'
}

export enum StatisticsDisplayFormat {
  Day = 'DAY',
  Hour = 'HOUR'
}

export enum StatisticsPeriod {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK'
}

export type SubscriptionAppointment = {
  __typename?: 'SubscriptionAppointment';
  action: AppointmentSubscriptionAction;
  appointment: Appointment;
};

export enum SubscriptionPlan {
  /** Free subscription */
  Free = 'FREE',
  /** Salon subscription */
  Salon = 'SALON'
}

export type Timerange = {
  __typename?: 'Timerange';
  /** Range beginning timestamp */
  from: Scalars['DateTime']['output'];
  /** Range end timestamp */
  to: Scalars['DateTime']['output'];
};

export type TimerangeInput = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

export type Treatment = {
  __typename?: 'Treatment';
  categoryUuid: Scalars['UUID4']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duration: Scalars['Int']['output'];
  hasEmployeesAssigned: Scalars['Boolean']['output'];
  imagesUrls: Array<Scalars['String']['output']>;
  insertedAt?: Maybe<Scalars['DateTime']['output']>;
  isDeleted: Scalars['Boolean']['output'];
  isExactPrice: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  price?: Maybe<Scalars['Int']['output']>;
  priceFrom?: Maybe<Scalars['Int']['output']>;
  priceTo?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid: Scalars['UUID4']['output'];
};

export type TreatmentConnection = {
  __typename?: 'TreatmentConnection';
  count: Scalars['Int']['output'];
  edges: Array<TreatmentEdge>;
  pageInfo: PageInfo;
};

export type TreatmentEdge = {
  __typename?: 'TreatmentEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Treatment;
};

export type UpdateAppointmentInput = {
  appointmentUuid: Scalars['UUID4']['input'];
  clientUuid?: InputMaybe<Scalars['UUID4']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  sendNotification: Scalars['Boolean']['input'];
  timeRange: TimerangeInput;
  treatment: AppointmentTreatmentInput;
};

export type UpdateBlockedSlotInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  employeeUuid: Scalars['UUID4']['input'];
  timeRange: TimerangeInput;
  title?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID4']['input'];
};

export type UpdateBulkAppointmentInput = {
  appointmentUuid: Scalars['UUID4']['input'];
  clientUuid?: InputMaybe<Scalars['UUID4']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  sendNotification: Scalars['Boolean']['input'];
  treatments: Array<UpdateBulkAppointmentTreatmentInput>;
};

export type UpdateBulkAppointmentTreatmentInput = {
  appointmentTreatmentUuid?: InputMaybe<Scalars['UUID4']['input']>;
  employeeUuid: Scalars['UUID4']['input'];
  timeRange: TimerangeInput;
  treatmentUuid: Scalars['UUID4']['input'];
};

export type UpdateCategoriesPositionsInput = {
  headCategoryUuid: Scalars['UUID4']['input'];
  sortedCategoryUuids: Array<Scalars['UUID4']['input']>;
};

export type UpdateCategoryInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateClientInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  imagesUrls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  sex?: InputMaybe<Sex>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  tagsUuids?: InputMaybe<Array<Scalars['UUID4']['input']>>;
};

export type UpdateEmployeeInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isAvailableForAppointments?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  treatmentsUuids?: InputMaybe<Array<Scalars['UUID4']['input']>>;
  workSchedule?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateEmployeesPositionsInput = {
  sortedEmployeesUuids: Array<Scalars['UUID4']['input']>;
};

export type UpdateEquipmentItemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  treatmentsUuids: Array<Scalars['UUID4']['input']>;
};

export type UpdateHeadCategoriesPositionsInput = {
  sortedHeadCategoryUuids: Array<Scalars['UUID4']['input']>;
};

export type UpdateHeadCategoryInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSalonInput = {
  backgroundImageUrl?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  links?: InputMaybe<Scalars['JSON']['input']>;
  logoImageUrl?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneType?: InputMaybe<PhoneType>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  selfBookingAutomaticalSlotApproval?: InputMaybe<Scalars['Boolean']['input']>;
  selfBookingAutomaticalSlotApprovalSms?: InputMaybe<Scalars['Boolean']['input']>;
  selfBookingMaxLeadTime?: InputMaybe<MaxLeadTimeOptionsInDays>;
  selfBookingMinLeadTime?: InputMaybe<MinLeadTimeOptionsInMinutes>;
  selfBookingSlotDuration?: InputMaybe<Scalars['Int']['input']>;
  socials?: InputMaybe<Scalars['JSON']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSalonOpeningHoursInput = {
  regular?: InputMaybe<Scalars['JSON']['input']>;
  special?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateSalonSmsDetailsInput = {
  sendReminders?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateSmsTemplateInput = {
  description: Scalars['String']['input'];
  templateUuid: Scalars['UUID4']['input'];
  title: Scalars['String']['input'];
};

export type UpdateTreatmentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  imagesUrls?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isExactPrice?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  priceFrom?: InputMaybe<Scalars['Int']['input']>;
  priceTo?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateTreatmentsPositionsInput = {
  categoryUuid: Scalars['UUID4']['input'];
  sortedTreatmentUuids: Array<Scalars['UUID4']['input']>;
};

export type AppointmentTreatmentFragment = { __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } };

export type TimerangeFragment = { __typename?: 'Timerange', from: any, to: any };

export type ClientCancelAppointmentMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type ClientCancelAppointmentMutation = { __typename?: 'RootMutationType', clientCancelAppointment: { __typename?: 'Appointment', appointmentUuid: any } };

export type FetchClientAppointmentQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type FetchClientAppointmentQuery = { __typename?: 'RootQueryType', fetchClientAppointment: { __typename?: 'ClientAppointment', treatments: Array<{ __typename?: 'AppointmentTreatment', appointmentTreatmentUuid: any, employeeUuid?: any | null, treatmentUuid: any, employeeName?: string | null, treatmentName: string, categoryColor?: string | null, finalPrice: number, suggestedPrice: number, timeRange: { __typename?: 'Timerange', from: any, to: any } }> } };

export type FetchReferralScoreQueryVariables = Exact<{
  referralId: Scalars['String']['input'];
}>;


export type FetchReferralScoreQuery = { __typename?: 'RootQueryType', fetchReferralScore: { __typename?: 'ReferralScore', name: string, score: number } };

export type AvailableBlockFragment = { __typename?: 'AvailableBlock', from: any };

export type EmployeeFragment = { __typename?: 'Employee', accountUuid: string, email: string, firstName?: string | null, lastName?: string | null, phone?: string | null, role: Role, isActive: boolean };

export type PublicCategoryFragment = { __typename?: 'PublicCategory', categoryName: string, categoryUuid: any, categoryColor: string, treatments?: Array<{ __typename?: 'PublicTreatment', treatmentName: string, treatmentUuid: any, treatmentDuration: number, treatmentIsExactPrice: boolean, treatmentPrice?: number | null, treatmentPriceFrom?: number | null, treatmentPriceTo?: number | null, treatmentDescription?: string | null } | null> | null };

export type PublicHeadCategoryFragment = { __typename?: 'PublicHeadCategory', headCategoryName: string, headCategoryUuid: any, categories?: Array<{ __typename?: 'PublicCategory', categoryName: string, categoryUuid: any, categoryColor: string, treatments?: Array<{ __typename?: 'PublicTreatment', treatmentName: string, treatmentUuid: any, treatmentDuration: number, treatmentIsExactPrice: boolean, treatmentPrice?: number | null, treatmentPriceFrom?: number | null, treatmentPriceTo?: number | null, treatmentDescription?: string | null } | null> | null } | null> | null };

export type PublicTreatmentFragment = { __typename?: 'PublicTreatment', treatmentName: string, treatmentUuid: any, treatmentDuration: number, treatmentIsExactPrice: boolean, treatmentPrice?: number | null, treatmentPriceFrom?: number | null, treatmentPriceTo?: number | null, treatmentDescription?: string | null };

export type SalonFragment = { __typename?: 'Salon', salonUuid: any, name: string, phone?: string | null, mobile?: string | null, email?: string | null, city?: string | null, links?: any | null, postalCode?: string | null, street?: string | null, streetNumber?: string | null, logoImageUrl?: string | null, backgroundImageUrl?: string | null, socials?: any | null, description?: string | null, slug?: string | null };

export type SalonOpeningHoursFragment = { __typename?: 'SalonOpeningHours', regular?: any | null, special?: any | null };

export type SearchTreatmentFragment = { __typename?: 'SearchTreatment', categoryColor: string, categoryName: string, categoryUuid: any, treatmentName: string, treatmentUuid: any, treatmentDuration: number, treatmentIsExactPrice?: boolean | null, treatmentPrice?: number | null, treatmentPriceFrom?: number | null, treatmentPriceTo?: number | null, treatmentDescription?: string | null };

export type ClientScheduleAppointmentMutationVariables = Exact<{
  data: ScheduleSelfBookingAppointmentInput;
}>;


export type ClientScheduleAppointmentMutation = { __typename?: 'RootMutationType', clientScheduleAppointment: { __typename?: 'Appointment', appointmentUuid: any } };

export type SendVerificationSmsMutationVariables = Exact<{
  phone: Scalars['String']['input'];
  slug: Scalars['String']['input'];
}>;


export type SendVerificationSmsMutation = { __typename?: 'RootMutationType', sendVerificationSms: boolean };

export type FetchAllPublicTreatmentsQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
}>;


export type FetchAllPublicTreatmentsQuery = { __typename?: 'RootQueryType', fetchAllPublicTreatments: { __typename?: 'SearchTreatmentConnection', count: number, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean }, edges: Array<{ __typename?: 'SearchTreatmentEdge', node: { __typename?: 'SearchTreatment', categoryColor: string, categoryName: string, categoryUuid: any, treatmentName: string, treatmentUuid: any, treatmentDuration: number, treatmentIsExactPrice?: boolean | null, treatmentPrice?: number | null, treatmentPriceFrom?: number | null, treatmentPriceTo?: number | null, treatmentDescription?: string | null } }> } };

export type FetchBulkAvailableSlotsQueryVariables = Exact<{
  data: BulkAvailabilityInput;
}>;


export type FetchBulkAvailableSlotsQuery = { __typename?: 'RootQueryType', fetchBulkAvailableSlots: Array<{ __typename?: 'AvailableBlock', from: any }> };

export type FetchPublicEmployeesQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  treatmentUuid?: InputMaybe<Scalars['UUID4']['input']>;
}>;


export type FetchPublicEmployeesQuery = { __typename?: 'RootQueryType', fetchPublicEmployees: Array<{ __typename?: 'Employee', accountUuid: string, email: string, firstName?: string | null, lastName?: string | null, phone?: string | null, role: Role, isActive: boolean }> };

export type FetchPublicSalonOpeningHoursQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type FetchPublicSalonOpeningHoursQuery = { __typename?: 'RootQueryType', fetchPublicSalonOpeningHours: { __typename?: 'SalonOpeningHours', regular?: any | null, special?: any | null } };

export type FetchPublicSalonQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type FetchPublicSalonQuery = { __typename?: 'RootQueryType', fetchPublicSalon: { __typename?: 'Salon', salonUuid: any, name: string, phone?: string | null, mobile?: string | null, email?: string | null, city?: string | null, links?: any | null, postalCode?: string | null, street?: string | null, streetNumber?: string | null, logoImageUrl?: string | null, backgroundImageUrl?: string | null, socials?: any | null, description?: string | null, slug?: string | null } };

export type FetchSalonPublicTreatmentsQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type FetchSalonPublicTreatmentsQuery = { __typename?: 'RootQueryType', fetchSalonPublicTreatments: Array<{ __typename?: 'PublicHeadCategory', headCategoryName: string, headCategoryUuid: any, categories?: Array<{ __typename?: 'PublicCategory', categoryName: string, categoryUuid: any, categoryColor: string, treatments?: Array<{ __typename?: 'PublicTreatment', treatmentName: string, treatmentUuid: any, treatmentDuration: number, treatmentIsExactPrice: boolean, treatmentPrice?: number | null, treatmentPriceFrom?: number | null, treatmentPriceTo?: number | null, treatmentDescription?: string | null } | null> | null } | null> | null }> };

export type FetchSalonsSlugsQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchSalonsSlugsQuery = { __typename?: 'RootQueryType', fetchSalonsSlugs: Array<string> };

export type SearchTreatmentQueryVariables = Exact<{
  treatmentUuid: Scalars['UUID4']['input'];
}>;


export type SearchTreatmentQuery = { __typename?: 'RootQueryType', publicSearchTreatment: { __typename?: 'SearchTreatment', categoryColor: string, categoryName: string, categoryUuid: any, treatmentName: string, treatmentUuid: any, treatmentDuration: number, treatmentIsExactPrice?: boolean | null, treatmentPrice?: number | null, treatmentPriceFrom?: number | null, treatmentPriceTo?: number | null, treatmentDescription?: string | null } };


      export type PossibleTypesResultData = {
  "possibleTypes": {
    "CalendarResult": [
      "Appointment",
      "BlockedSlot"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "CalendarResult": [
      "Appointment",
      "BlockedSlot"
    ]
  }
};
      export default result;
    