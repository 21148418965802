import '@/styles/tailwind.css'
import '@/styles/react-datepicker.css'
import 'focus-visible'

import { ApolloProvider } from '@apollo/client'
import { ReactElement } from 'react'
import { CookiesProvider } from 'react-cookie'

import { appWithTranslation } from 'next-i18next'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import NextNProgress from 'nextjs-progressbar'

import { ENV } from '@/constants/env'
import { useAnalyticsStore } from '@/hooks/use-analytics-store'
import { useCurrentUrl } from '@/hooks/use-current-url'
import { useDefaultDateOptions } from '@/hooks/use-default-date-options'
import { apolloClient } from '@/lib/apollo-client'
import { ScriptClarity } from '@/lib/clarity'
import { ScriptGTM, ScriptGTMConsentUpdate } from '@/lib/gtm'
import { getAlternateUrl } from '@/utils/locale'

// @ts-expect-error - example from documentation gives error
const DynamicCookieBanner = dynamic(() =>
  // @ts-expect-error - dynamic import with named export
  import('@/components/CookieBanner').then((mod) => mod.CookieBanner, {
    ssr: false,
  })
)
const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()

  const { currentUrl } = useCurrentUrl()
  const { isAnalyticsEnabled } = useAnalyticsStore()

  useDefaultDateOptions()

  return (
    <>
      <Head>
        <meta property="og:site_name" content="Estetify" />
        <meta property="og:url" content={currentUrl} />
        <meta
          name="viewport"
          content="width=device-width,height=device-height,initial-scale=1.0,user-scalable=no,user-scalable=0"
        />
        {router.locales?.reduce((acc: ReactElement[], locale) => {
          acc.push(
            <link
              key={locale}
              rel="alternate"
              hrefLang={locale}
              href={getAlternateUrl(router.asPath, locale)}
            />
          )

          return acc
        }, [])}
      </Head>
      <ScriptGTM />
      {isAnalyticsEnabled && <ScriptGTMConsentUpdate />}
      {ENV.CLARITY_ID && <ScriptClarity />}
      <NextNProgress color="#8b5cf6" />
      <CookiesProvider>
        <ApolloProvider client={apolloClient}>
          <Component {...pageProps} />
        </ApolloProvider>
        {typeof window !== 'undefined' && <DynamicCookieBanner />}
      </CookiesProvider>
    </>
  )
}

export default appWithTranslation(App)
