import { useCookies } from 'react-cookie'

import { YEAR_IN_SECONDS } from '@/constants'

type ConsentParams = 'granted' | 'denied' | undefined

type Cookies = {
  consent?: ConsentParams
}

const GRANTED = 'granted'
const DENIED = 'denied'

const updateGTag = (consentValue: ConsentParams) => {
  if (typeof window !== 'undefined' && window.gtag !== undefined) {
    window.gtag('consent', 'update', {
      ad_storage: consentValue,
      analytics_storage: consentValue,
    })
  }
}

export const useAnalyticsStore = () => {
  const [cookies, setCookie] = useCookies<'consent', Cookies>()

  const consent = cookies?.consent ?? DENIED

  const enableAnalytics = (enable: boolean) => {
    const consentValue = enable ? GRANTED : DENIED
    setCookie('consent', consentValue, {
      maxAge: YEAR_IN_SECONDS,
    })
    updateGTag(consentValue)
  }

  return {
    hasUserChose:
      cookies?.consent && [GRANTED, DENIED].includes(cookies.consent),
    isAnalyticsEnabled: consent === GRANTED,
    enableAnalytics,
  }
}
