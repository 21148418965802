export const ENV = {
  SITE_URL: process.env.NEXT_PUBLIC_FE_URL ?? 'https://estetify.pl',
  APP_URL: process.env.NEXT_PUBLIC_APP_URL ?? 'https://app.estetify.pl',
  GTM_TRACKING_ID: process.env.NEXT_PUBLIC_GTM_ID ?? 'GTM-XXXXXXX',
  APP_STORE_URL:
    process.env.NEXT_PUBLIC_APP_STORE_URL ??
    'https://apps.apple.com/pl/app/estetify/id6444611744',
  GOOGLE_PLAY_URL:
    process.env.NEXT_PUBLIC_GOOGLE_PLAY_URL ??
    'https://play.google.com/store/apps/details?id=com.estetify',
  CLARITY_ID: process.env.NEXT_PUBLIC_CLARITY_ID,
  EMAIL: process.env.NEXT_PUBLIC_CONTACT_EMAIL ?? 'biuro@estetify.pl',
}
