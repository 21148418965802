import { useEffect } from 'react'
import { registerLocale, setDefaultLocale } from 'react-datepicker'

import en from 'date-fns/locale/en-US/index.js'
import pl from 'date-fns/locale/pl/index.js'
import uk from 'date-fns/locale/uk/index.js'
import setDefaultOptions from 'date-fns/setDefaultOptions'
import { useRouter } from 'next/router'

export const useDefaultDateOptions = () => {
  const { locale: routerLocale = '' } = useRouter()

  const setDefaults = async () => {
    try {
      let locale: Locale

      switch (routerLocale) {
        case 'en':
          locale = en
          break
        case 'pl':
          locale = pl
          break
        case 'uk':
          locale = uk
          break
        default:
          locale = pl
          break
      }

      setDefaultOptions({ locale, weekStartsOn: 1 })
      registerLocale(routerLocale, locale)
      setDefaultLocale(routerLocale)
    } catch (error) {
      console.warn(error)
    }
  }

  useEffect(() => {
    setDefaults()
  }, [routerLocale])
}
