import { from, createHttpLink, ApolloClient } from '@apollo/client'

import { getCachePolicy } from '@/lib/apollo-cache-policy'

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_API_URL,
})

export const apolloClient = new ApolloClient({
  ssrMode: true,
  link: from([httpLink]),
  cache: getCachePolicy(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
  connectToDevTools: process.env.NEXT_PUBLIC_ENV !== 'PROD',
})
