module.exports = [
  // Salons
  {
    rewritePattern: /^\/pl\/salony/,
    rewriteReplaceString: '/pl/salons',

    redirectPattern: /^\/pl\/salons/,
    redirectReplaceString: '/pl/salony',
  },
  {
    rewritePattern: /^\/uk\/salony/,
    rewriteReplaceString: '/uk/salons',

    redirectPattern: /^\/uk\/salons/,
    redirectReplaceString: '/uk/salony',
  },
  {
    rewritePattern: /^\/en\/salony/,
    rewriteReplaceString: '/en/salons',

    redirectPattern: /^\/en\/salony/,
    redirectReplaceString: '/en/salons',
  },

  // Changelog
  {
    rewritePattern: /^\/pl\/dziennik-zmian/,
    rewriteReplaceString: '/pl/changelog',

    redirectPattern: /^\/pl\/changelog/,
    redirectReplaceString: '/pl/dziennik-zmian',
  },
  {
    rewritePattern: /^\/pl\/zhurnal-zmin/,
    rewriteReplaceString: '/pl/changelog',

    redirectPattern: /^\/pl\/zhurnal-zmin/,
    redirectReplaceString: '/pl/dziennik-zmian',
  },

  {
    rewritePattern: /^\/uk\/zhurnal-zmin/,
    rewriteReplaceString: '/uk/changelog',

    redirectPattern: /^\/uk\/changelog/,
    redirectReplaceString: '/uk/zhurnal-zmin',
  },
  {
    rewritePattern: /^\/uk\/dziennik-zmian/,
    rewriteReplaceString: '/uk/changelog',

    redirectPattern: /^\/uk\/dziennik-zmian/,
    redirectReplaceString: '/uk/changelog',
  },

  {
    rewritePattern: /^\/en\/zhurnal-zmin/,
    rewriteReplaceString: '/en/changelog',

    redirectPattern: /^\/en\/zhurnal-zmin/,
    redirectReplaceString: '/en/changelog',
  },
  {
    rewritePattern: /^\/en\/dziennik-zmian/,
    rewriteReplaceString: '/en/changelog',

    redirectPattern: /^\/en\/dziennik-zmian/,
    redirectReplaceString: '/en/changelog',
  },

  // Features - calendar
  {
    rewritePattern: /^\/pl\/funkcje\/kalendarz/,
    rewriteReplaceString: '/pl/features/calendar',

    redirectPattern: /^\/pl\/features\/calendar/,
    redirectReplaceString: '/pl/funkcje/kalendarz',
  },
  {
    rewritePattern: /^\/pl\/osoblyvosti\/kalendar/,
    rewriteReplaceString: '/pl/features/calendar',

    redirectPattern: /^\/pl\/osoblyvosti\/kalendar/,
    redirectReplaceString: '/pl/funkcje/kalendarz',
  },

  {
    rewritePattern: /^\/uk\/osoblyvosti\/kalendar/,
    rewriteReplaceString: '/uk/features/calendar',

    redirectPattern: /^\/uk\/features\/calendar/,
    redirectReplaceString: '/uk/osoblyvosti/kalendar',
  },
  {
    rewritePattern: /^\/uk\/funkcje\/kalendarz/,
    rewriteReplaceString: '/uk/features/calendar',

    redirectPattern: /^\/uk\/funkcje\/kalendarz/,
    redirectReplaceString: '/uk/features/calendar',
  },

  {
    rewritePattern: /^\/en\/osoblyvosti\/kalendar/,
    rewriteReplaceString: '/en/features/calendar',

    redirectPattern: /^\/en\/osoblyvosti\/kalendar/,
    redirectReplaceString: '/en/features/calendar',
  },
  {
    rewritePattern: /^\/en\/funkcje\/kalendarz/,
    rewriteReplaceString: '/en/features/calendar',

    redirectPattern: /^\/en\/funkcje\/kalendarz/,
    redirectReplaceString: '/en/features/calendar',
  },

  // Features - messages
  {
    rewritePattern: /^\/pl\/funkcje\/wiadomosci-sms/,
    rewriteReplaceString: '/pl/features/messages',

    redirectPattern: /^\/pl\/features\/messages/,
    redirectReplaceString: '/pl/funkcje/wiadomosci-sms',
  },
  {
    rewritePattern: /^\/pl\/osoblyvosti\/sms-povidomlennya/,
    rewriteReplaceString: '/pl/features/messages',

    redirectPattern: /^\/pl\/osoblyvosti\/sms-povidomlennya/,
    redirectReplaceString: '/pl/funkcje/wiadomosci-sms',
  },

  {
    rewritePattern: /^\/uk\/osoblyvosti\/sms-povidomlennya/,
    rewriteReplaceString: '/uk/features/messages',

    redirectPattern: /^\/uk\/features\/messages/,
    redirectReplaceString: '/uk/osoblyvosti/sms-povidomlennya',
  },
  {
    rewritePattern: /^\/uk\/funkcje\/wiadomosci-sms/,
    rewriteReplaceString: '/uk/features/messages',

    redirectPattern: /^\/uk\/funkcje\/wiadomosci-sms/,
    redirectReplaceString: '/uk/features/messages',
  },

  {
    rewritePattern: /^\/en\/osoblyvosti\/sms-povidomlennya/,
    rewriteReplaceString: '/en/features/messages',

    redirectPattern: /^\/en\/osoblyvosti\/sms-povidomlennya/,
    redirectReplaceString: '/en/features/messages',
  },
  {
    rewritePattern: /^\/en\/funkcje\/wiadomosci-sms/,
    rewriteReplaceString: '/en/features/messages',

    redirectPattern: /^\/en\/funkcje\/wiadomosci-sms/,
    redirectReplaceString: '/en/features/messages',
  },

  // Features - messages
  {
    rewritePattern: /^\/pl\/funkcje\/self-booking/,
    rewriteReplaceString: '/pl/features/self-booking',

    redirectPattern: /^\/pl\/features\/self-booking/,
    redirectReplaceString: '/pl/funkcje/self-booking',
  },
  {
    rewritePattern: /^\/pl\/osoblyvosti\/self-booking/,
    rewriteReplaceString: '/pl/features/self-booking',

    redirectPattern: /^\/pl\/osoblyvosti\/self-booking/,
    redirectReplaceString: '/pl/funkcje/self-booking',
  },

  {
    rewritePattern: /^\/uk\/osoblyvosti\/self-booking/,
    rewriteReplaceString: '/uk/features/self-booking',

    redirectPattern: /^\/uk\/features\/self-booking/,
    redirectReplaceString: '/uk/osoblyvosti/self-booking',
  },
  {
    rewritePattern: /^\/uk\/funkcje\/self-booking/,
    rewriteReplaceString: '/uk/features/self-booking',

    redirectPattern: /^\/uk\/funkcje\/self-booking/,
    redirectReplaceString: '/uk/features/self-booking',
  },

  {
    rewritePattern: /^\/en\/osoblyvosti\/self-booking/,
    rewriteReplaceString: '/en/features/self-booking',

    redirectPattern: /^\/en\/osoblyvosti\/self-booking/,
    redirectReplaceString: '/en/features/self-booking',
  },
  {
    rewritePattern: /^\/en\/funkcje\/self-booking/,
    rewriteReplaceString: '/en/features/self-booking',

    redirectPattern: /^\/en\/funkcje\/self-booking/,
    redirectReplaceString: '/en/features/self-booking',
  },

  // Features - management
  {
    rewritePattern: /^\/pl\/funkcje\/zarzadzanie/,
    rewriteReplaceString: '/pl/features/management',

    redirectPattern: /^\/pl\/features\/management/,
    redirectReplaceString: '/pl/funkcje/zarzadzanie',
  },
  {
    rewritePattern: /^\/pl\/osoblyvosti\/upravlinnya/,
    rewriteReplaceString: '/pl/features/management',

    redirectPattern: /^\/pl\/osoblyvosti\/upravlinnya/,
    redirectReplaceString: '/pl/funkcje/zarzadzanie',
  },

  {
    rewritePattern: /^\/uk\/osoblyvosti\/upravlinnya/,
    rewriteReplaceString: '/uk/features/management',

    redirectPattern: /^\/uk\/features\/management/,
    redirectReplaceString: '/uk/osoblyvosti/upravlinnya',
  },
  {
    rewritePattern: /^\/uk\/funkcje\/zarzadzanie/,
    rewriteReplaceString: '/uk/features/management',

    redirectPattern: /^\/uk\/funkcje\/zarzadzanie/,
    redirectReplaceString: '/uk/features/management',
  },

  {
    rewritePattern: /^\/en\/osoblyvosti\/upravlinnya/,
    rewriteReplaceString: '/en/features/management',

    redirectPattern: /^\/en\/osoblyvosti\/upravlinnya/,
    redirectReplaceString: '/en/features/management',
  },
  {
    rewritePattern: /^\/en\/funkcje\/zarzadzanie/,
    rewriteReplaceString: '/en/features/management',

    redirectPattern: /^\/en\/funkcje\/zarzadzanie/,
    redirectReplaceString: '/en/features/management',
  },

  // Documents - privacy policy
  {
    rewritePattern: /^\/pl\/dokumenty\/polityka-prywatnosci/,
    rewriteReplaceString: '/pl/documents/privacy-policy',

    redirectPattern: /^\/pl\/documents\/privacy-policy/,
    redirectReplaceString: '/pl/dokumenty/polityka-prywatnosci',
  },
  {
    rewritePattern: /^\/pl\/dokumentiv\/polityka-konfidentsiynosti/,
    rewriteReplaceString: '/pl/documents/privacy-policy',

    redirectPattern: /^\/pl\/dokumentiv\/polityka-konfidentsiynosti/,
    redirectReplaceString: '/pl/dokumenty/polityka-prywatnosci',
  },

  {
    rewritePattern: /^\/uk\/dokumentiv\/polityka-konfidentsiynosti/,
    rewriteReplaceString: '/uk/documents/privacy-policy',

    redirectPattern: /^\/uk\/documents\/privacy-policy/,
    redirectReplaceString: '/uk/dokumentiv/polityka-konfidentsiynosti',
  },
  {
    rewritePattern: /^\/uk\/dokumenty\/polityka-prywatnosci/,
    rewriteReplaceString: '/uk/documents/privacy-policy',

    redirectPattern: /^\/uk\/dokumenty\/polityka-prywatnosci/,
    redirectReplaceString: '/uk/documents/privacy-policy',
  },

  {
    rewritePattern: /^\/en\/dokumentiv\/polityka-konfidentsiynosti/,
    rewriteReplaceString: '/en/documents/privacy-policy',

    redirectPattern: /^\/en\/dokumentiv\/polityka-konfidentsiynosti/,
    redirectReplaceString: '/en/documents/privacy-policy',
  },
  {
    rewritePattern: /^\/en\/dokumenty\/polityka-prywatnosci/,
    rewriteReplaceString: '/en/documents/privacy-policy',

    redirectPattern: /^\/en\/dokumenty\/polityka-prywatnosci/,
    redirectReplaceString: '/en/documents/privacy-policy',
  },

  // Documents - regulations
  {
    rewritePattern: /^\/pl\/dokumenty\/regulamin/,
    rewriteReplaceString: '/pl/documents/regulations',

    redirectPattern: /^\/pl\/documents\/regulations/,
    redirectReplaceString: '/pl/dokumenty/regulamin',
  },
  {
    rewritePattern: /^\/pl\/dokumentiv\/statut/,
    rewriteReplaceString: '/pl/documents/regulations',

    redirectPattern: /^\/pl\/dokumentiv\/statut/,
    redirectReplaceString: '/pl/dokumenty/regulamin',
  },

  {
    rewritePattern: /^\/uk\/dokumentiv\/statut/,
    rewriteReplaceString: '/uk/documents/regulations',

    redirectPattern: /^\/uk\/documents\/regulations/,
    redirectReplaceString: '/uk/dokumentiv/statut',
  },
  {
    rewritePattern: /^\/uk\/dokumenty\/regulamin/,
    rewriteReplaceString: '/uk/documents/regulations',

    redirectPattern: /^\/uk\/dokumenty\/regulamin/,
    redirectReplaceString: '/uk/documents/regulations',
  },

  {
    rewritePattern: /^\/en\/dokumentiv\/statut/,
    rewriteReplaceString: '/en/documents/regulations',

    redirectPattern: /^\/en\/dokumentiv\/statut/,
    redirectReplaceString: '/en/documents/regulations',
  },
  {
    rewritePattern: /^\/en\/dokumenty\/regulamin/,
    rewriteReplaceString: '/en/documents/regulations',

    redirectPattern: /^\/en\/dokumenty\/regulamin/,
    redirectReplaceString: '/en/documents/regulations',
  },

  // Comparison with Booksy
  {
    rewritePattern: /^\/pl\/porownanie-z-booksy/,
    rewriteReplaceString: '/pl/comparison-with-booksy',

    redirectPattern: /^\/pl\/comparison-with-booksy/,
    redirectReplaceString: '/pl/porownanie-z-booksy',
  },
  {
    rewritePattern: /^\/pl\/porivnyannya-z-booksy/,
    rewriteReplaceString: '/pl/comparison-with-booksy',

    redirectPattern: /^\/pl\/porivnyannya-z-booksy/,
    redirectReplaceString: '/pl/porownanie-z-booksy',
  },

  {
    rewritePattern: /^\/uk\/porivnyannya-z-booksy/,
    rewriteReplaceString: '/uk/comparison-with-booksy',

    redirectPattern: /^\/uk\/comparison-with-booksy/,
    redirectReplaceString: '/uk/porivnyannya-z-booksy',
  },
  {
    rewritePattern: /^\/uk\/porownanie-z-booksy/,
    rewriteReplaceString: '/uk/comparison-with-booksy',

    redirectPattern: /^\/uk\/porownanie-z-booksy/,
    redirectReplaceString: '/uk/comparison-with-booksy',
  },

  {
    rewritePattern: /^\/en\/porivnyannya-z-booksy/,
    rewriteReplaceString: '/en/comparison-with-booksy',

    redirectPattern: /^\/en\/porivnyannya-z-booksy/,
    redirectReplaceString: '/en/comparison-with-booksy',
  },
  {
    rewritePattern: /^\/en\/porownanie-z-booksy/,
    rewriteReplaceString: '/en/comparison-with-booksy',

    redirectPattern: /^\/en\/porownanie-z-booksy/,
    redirectReplaceString: '/en/comparison-with-booksy',
  },

  // Refferal program
  {
    rewritePattern: /^\/pl\/program-polecen/,
    rewriteReplaceString: '/pl/referral-program',

    redirectPattern: /^\/pl\/referral-program/,
    redirectReplaceString: '/pl/program-polecen',
  },
  {
    rewritePattern: /^\/pl\/referalna-prohrama/,
    rewriteReplaceString: '/pl/referral-program',

    redirectPattern: /^\/pl\/referalna-prohrama/,
    redirectReplaceString: '/pl/program-polecen',
  },

  {
    rewritePattern: /^\/uk\/referalna-prohrama/,
    rewriteReplaceString: '/uk/referral-program',

    redirectPattern: /^\/uk\/referral-program/,
    redirectReplaceString: '/uk/referalna-prohrama',
  },
  {
    rewritePattern: /^\/uk\/program-polecen/,
    rewriteReplaceString: '/uk/referral-program',

    redirectPattern: /^\/uk\/program-polecen/,
    redirectReplaceString: '/uk/referral-program',
  },

  {
    rewritePattern: /^\/en\/referalna-prohrama/,
    rewriteReplaceString: '/en/referral-program',

    redirectPattern: /^\/en\/referalna-prohrama/,
    redirectReplaceString: '/en/referral-program',
  },
  {
    rewritePattern: /^\/en\/program-polecen/,
    rewriteReplaceString: '/en/referral-program',

    redirectPattern: /^\/en\/program-polecen/,
    redirectReplaceString: '/en/referral-program',
  },

  // Blog five elements
  {
    rewritePattern:
      /^\/pl\/blog\/five-elements-a-beauty-salon-website-should-have/,
    rewriteReplaceString:
      '/pl/blog/piec-elementow-na-stronie-salonu-kosmetycznego',

    redirectPattern:
      /^\/pl\/blog\/five-elements-a-beauty-salon-website-should-have/,
    redirectReplaceString:
      '/pl/blog/piec-elementow-na-stronie-salonu-kosmetycznego',
  },
  {
    rewritePattern:
      /^\/pl\/blog\/piat-elementiv-yaki-povynen-mistyty-veb-sait-salonu-krasy/,
    rewriteReplaceString:
      '/pl/blog/piec-elementow-na-stronie-salonu-kosmetycznego',

    redirectPattern:
      /^\/pl\/blog\/piat-elementiv-yaki-povynen-mistyty-veb-sait-salonu-krasy/,
    redirectReplaceString:
      '/pl/blog/piec-elementow-na-stronie-salonu-kosmetycznego',
  },

  {
    rewritePattern:
      /^\/en\/blog\/piec-elementow-na-stronie-salonu-kosmetycznego/,
    rewriteReplaceString:
      '/en/blog/five-elements-a-beauty-salon-website-should-have',

    redirectPattern:
      /^\/en\/blog\/piec-elementow-na-stronie-salonu-kosmetycznego/,
    redirectReplaceString:
      '/en/blog/five-elements-a-beauty-salon-website-should-have',
  },
  {
    rewritePattern:
      /^\/en\/blog\/piat-elementiv-yaki-povynen-mistyty-veb-sait-salonu-krasy/,
    rewriteReplaceString:
      '/en/blog/five-elements-a-beauty-salon-website-should-have',

    redirectPattern:
      /^\/en\/blog\/piat-elementiv-yaki-povynen-mistyty-veb-sait-salonu-krasy/,
    redirectReplaceString:
      '/en/blog/five-elements-a-beauty-salon-website-should-have',
  },

  {
    rewritePattern:
      /^\/uk\/blog\/piec-elementow-na-stronie-salonu-kosmetycznego/,
    rewriteReplaceString:
      '/uk/blog/piat-elementiv-yaki-povynen-mistyty-veb-sait-salonu-krasy',

    redirectPattern:
      /^\/uk\/blog\/piec-elementow-na-stronie-salonu-kosmetycznego/,
    redirectReplaceString:
      '/uk/blog/piat-elementiv-yaki-povynen-mistyty-veb-sait-salonu-krasy',
  },
  {
    rewritePattern:
      /^\/uk\/blog\/five-elements-a-beauty-salon-website-should-have/,
    rewriteReplaceString:
      '/uk/blog/piat-elementiv-yaki-povynen-mistyty-veb-sait-salonu-krasy',

    redirectPattern:
      /^\/uk\/blog\/five-elements-a-beauty-salon-website-should-have/,
    redirectReplaceString:
      '/uk/blog/piat-elementiv-yaki-povynen-mistyty-veb-sait-salonu-krasy',
  },

  // Blog sensory marketing
  {
    rewritePattern: /^\/pl\/blog\/sensory-marketing-in-a-beauty-salon/,
    rewriteReplaceString: '/pl/blog/marketing-sensoryczny-w-salonie-beauty',

    redirectPattern: /^\/pl\/blog\/sensory-marketing-in-a-beauty-salon/,
    redirectReplaceString: '/pl/blog/marketing-sensoryczny-w-salonie-beauty',
  },
  {
    rewritePattern: /^\/pl\/blog\/sensornyi-marketynh-v-saloni-krasy/,
    rewriteReplaceString: '/pl/blog/marketing-sensoryczny-w-salonie-beauty',

    redirectPattern: /^\/pl\/blog\/sensornyi-marketynh-v-saloni-krasy/,
    redirectReplaceString: '/pl/blog/marketing-sensoryczny-w-salonie-beauty',
  },

  {
    rewritePattern: /^\/en\/blog\/marketing-sensoryczny-w-salonie-beauty/,
    rewriteReplaceString: '/en/blog/sensory-marketing-in-a-beauty-salon',

    redirectPattern: /^\/en\/blog\/marketing-sensoryczny-w-salonie-beauty/,
    redirectReplaceString: '/en/blog/sensory-marketing-in-a-beauty-salon',
  },
  {
    rewritePattern: /^\/en\/blog\/sensornyi-marketynh-v-saloni-krasy/,
    rewriteReplaceString: '/en/blog/sensory-marketing-in-a-beauty-salon',

    redirectPattern: /^\/en\/blog\/sensornyi-marketynh-v-saloni-krasy/,
    redirectReplaceString: '/en/blog/sensory-marketing-in-a-beauty-salon',
  },

  {
    rewritePattern: /^\/uk\/blog\/marketing-sensoryczny-w-salonie-beauty/,
    rewriteReplaceString: '/uk/blog/sensornyi-marketynh-v-saloni-krasy',

    redirectPattern: /^\/uk\/blog\/marketing-sensoryczny-w-salonie-beauty/,
    redirectReplaceString: '/uk/blog/sensornyi-marketynh-v-saloni-krasy',
  },
  {
    rewritePattern: /^\/uk\/blog\/sensory-marketing-in-a-beauty-salon/,
    rewriteReplaceString: '/uk/blog/sensornyi-marketynh-v-saloni-krasy',

    redirectPattern: /^\/uk\/blog\/sensory-marketing-in-a-beauty-salon/,
    redirectReplaceString: '/uk/blog/sensornyi-marketynh-v-saloni-krasy',
  },

  // Blog marketing in the beauty industry
  {
    rewritePattern:
      /^\/pl\/blog\/marketing-in-the-beauty-industry-what-to-look-out-for-in-your-salons-marketing-efforts/,
    rewriteReplaceString:
      '/pl/blog/marketing-w-branzy-beauty-na-co-zwrocic-uwage-w-dzialaniach-marketingowych-swojego-salonu',

    redirectPattern:
      /^\/pl\/blog\/marketing-in-the-beauty-industry-what-to-look-out-for-in-your-salons-marketing-efforts/,
    redirectReplaceString:
      '/pl/blog/marketing-w-branzy-beauty-na-co-zwrocic-uwage-w-dzialaniach-marketingowych-swojego-salonu',
  },
  {
    rewritePattern:
      /^\/pl\/blog\/marketynh-v-industrii-krasy-na-scho-slid-zvernuty-uvahu-v-marketynhovykh-zusylliakh-vashykh-saloniv/,
    rewriteReplaceString:
      '/pl/blog/marketing-w-branzy-beauty-na-co-zwrocic-uwage-w-dzialaniach-marketingowych-swojego-salonu',

    redirectPattern:
      /^\/pl\/blog\/marketynh-v-industrii-krasy-na-scho-slid-zvernuty-uvahu-v-marketynhovykh-zusylliakh-vashykh-saloniv/,
    redirectReplaceString:
      '/pl/blog/marketing-w-branzy-beauty-na-co-zwrocic-uwage-w-dzialaniach-marketingowych-swojego-salonu',
  },

  {
    rewritePattern:
      /^\/en\/blog\/marketing-w-branzy-beauty-na-co-zwrocic-uwage-w-dzialaniach-marketingowych-swojego-salonu/,
    rewriteReplaceString:
      '/en/blog/marketing-in-the-beauty-industry-what-to-look-out-for-in-your-salons-marketing-efforts',

    redirectPattern:
      /^\/en\/blog\/marketing-w-branzy-beauty-na-co-zwrocic-uwage-w-dzialaniach-marketingowych-swojego-salonu/,
    redirectReplaceString:
      '/en/blog/marketing-in-the-beauty-industry-what-to-look-out-for-in-your-salons-marketing-efforts',
  },
  {
    rewritePattern:
      /^\/en\/blog\/marketynh-v-industrii-krasy-na-scho-slid-zvernuty-uvahu-v-marketynhovykh-zusylliakh-vashykh-saloniv/,
    rewriteReplaceString:
      '/en/blog/marketing-in-the-beauty-industry-what-to-look-out-for-in-your-salons-marketing-efforts',

    redirectPattern:
      /^\/en\/blog\/marketynh-v-industrii-krasy-na-scho-slid-zvernuty-uvahu-v-marketynhovykh-zusylliakh-vashykh-saloniv/,
    redirectReplaceString:
      '/en/blog/marketing-in-the-beauty-industry-what-to-look-out-for-in-your-salons-marketing-efforts',
  },

  {
    rewritePattern:
      /^\/uk\/blog\/marketing-w-branzy-beauty-na-co-zwrocic-uwage-w-dzialaniach-marketingowych-swojego-salonu/,
    rewriteReplaceString:
      '/uk/blog/marketynh-v-industrii-krasy-na-scho-slid-zvernuty-uvahu-v-marketynhovykh-zusylliakh-vashykh-saloniv',

    redirectPattern:
      /^\/uk\/blog\/marketing-w-branzy-beauty-na-co-zwrocic-uwage-w-dzialaniach-marketingowych-swojego-salonu/,
    redirectReplaceString:
      '/uk/blog/marketynh-v-industrii-krasy-na-scho-slid-zvernuty-uvahu-v-marketynhovykh-zusylliakh-vashykh-saloniv',
  },
  {
    rewritePattern:
      /^\/uk\/blog\/marketing-in-the-beauty-industry-what-to-look-out-for-in-your-salons-marketing-efforts/,
    rewriteReplaceString:
      '/uk/blog/marketynh-v-industrii-krasy-na-scho-slid-zvernuty-uvahu-v-marketynhovykh-zusylliakh-vashykh-saloniv',

    redirectPattern:
      /^\/uk\/blog\/marketing-in-the-beauty-industry-what-to-look-out-for-in-your-salons-marketing-efforts/,
    redirectReplaceString:
      '/uk/blog/marketynh-v-industrii-krasy-na-scho-slid-zvernuty-uvahu-v-marketynhovykh-zusylliakh-vashykh-saloniv',
  },

  // Blog how to price beauty salon services
  {
    rewritePattern: /^\/pl\/blog\/how-to-price-beauty-salon-services/,
    rewriteReplaceString: '/pl/blog/jak-wycenic-swoje-uslugi-w-salonie-beauty',

    redirectPattern: /^\/pl\/blog\/how-to-price-beauty-salon-services/,
    redirectReplaceString: '/pl/blog/jak-wycenic-swoje-uslugi-w-salonie-beauty',
  },
  {
    rewritePattern: /^\/pl\/blog\/yak-otsiniuvaty-posluhy-salonu-krasy/,
    rewriteReplaceString: '/pl/blog/jak-wycenic-swoje-uslugi-w-salonie-beauty',

    redirectPattern: /^\/pl\/blog\/yak-otsiniuvaty-posluhy-salonu-krasy/,
    redirectReplaceString: '/pl/blog/jak-wycenic-swoje-uslugi-w-salonie-beauty',
  },

  {
    rewritePattern: /^\/en\/blog\/jak-wycenic-swoje-uslugi-w-salonie-beauty/,
    rewriteReplaceString: '/en/blog/how-to-price-beauty-salon-services',

    redirectPattern: /^\/en\/blog\/jak-wycenic-swoje-uslugi-w-salonie-beauty/,
    redirectReplaceString: '/en/blog/how-to-price-beauty-salon-services',
  },
  {
    rewritePattern: /^\/en\/blog\/yak-otsiniuvaty-posluhy-salonu-krasy/,
    rewriteReplaceString: '/en/blog/how-to-price-beauty-salon-services',

    redirectPattern: /^\/en\/blog\/yak-otsiniuvaty-posluhy-salonu-krasy/,
    redirectReplaceString: '/en/blog/how-to-price-beauty-salon-services',
  },

  {
    rewritePattern: /^\/uk\/blog\/jak-wycenic-swoje-uslugi-w-salonie-beauty/,
    rewriteReplaceString: '/uk/blog/yak-otsiniuvaty-posluhy-salonu-krasy',

    redirectPattern: /^\/uk\/blog\/jak-wycenic-swoje-uslugi-w-salonie-beauty/,
    redirectReplaceString: '/uk/blog/yak-otsiniuvaty-posluhy-salonu-krasy',
  },
  {
    rewritePattern: /^\/uk\/blog\/how-to-price-beauty-salon-services/,
    rewriteReplaceString: '/uk/blog/yak-otsiniuvaty-posluhy-salonu-krasy',

    redirectPattern: /^\/uk\/blog\/how-to-price-beauty-salon-services/,
    redirectReplaceString: '/uk/blog/yak-otsiniuvaty-posluhy-salonu-krasy',
  },

  // Blog how to raise prices in a beauty salon and not lose customers
  {
    rewritePattern:
      /^\/pl\/blog\/how-to-raise-prices-in-a-beauty-salon-and-not-lose-customers/,
    rewriteReplaceString:
      '/pl/blog/jak-podniesc-ceny-w-salonie-kosmetycznym-i-nie-stracic-klientow',

    redirectPattern:
      /^\/pl\/blog\/how-to-raise-prices-in-a-beauty-salon-and-not-lose-customers/,
    redirectReplaceString:
      '/pl/blog/jak-podniesc-ceny-w-salonie-kosmetycznym-i-nie-stracic-klientow',
  },
  {
    rewritePattern:
      /^\/pl\/blog\/yak-pidniaty-tsiny-v-saloni-krasy-i-ne-vtratyty-kliientiv/,
    rewriteReplaceString:
      '/pl/blog/jak-podniesc-ceny-w-salonie-kosmetycznym-i-nie-stracic-klientow',

    redirectPattern:
      /^\/pl\/blog\/yak-pidniaty-tsiny-v-saloni-krasy-i-ne-vtratyty-kliientiv/,
    redirectReplaceString:
      '/pl/blog/jak-podniesc-ceny-w-salonie-kosmetycznym-i-nie-stracic-klientow',
  },

  {
    rewritePattern:
      /^\/en\/blog\/jak-podniesc-ceny-w-salonie-kosmetycznym-i-nie-stracic-klientow/,
    rewriteReplaceString:
      '/en/blog/how-to-raise-prices-in-a-beauty-salon-and-not-lose-customers',

    redirectPattern:
      /^\/en\/blog\/jak-podniesc-ceny-w-salonie-kosmetycznym-i-nie-stracic-klientow/,
    redirectReplaceString:
      '/en/blog/how-to-raise-prices-in-a-beauty-salon-and-not-lose-customers',
  },
  {
    rewritePattern:
      /^\/en\/blog\/yak-pidniaty-tsiny-v-saloni-krasy-i-ne-vtratyty-kliientiv/,
    rewriteReplaceString:
      '/en/blog/how-to-raise-prices-in-a-beauty-salon-and-not-lose-customers',

    redirectPattern:
      /^\/en\/blog\/yak-pidniaty-tsiny-v-saloni-krasy-i-ne-vtratyty-kliientiv/,
    redirectReplaceString:
      '/en/blog/how-to-raise-prices-in-a-beauty-salon-and-not-lose-customers',
  },

  {
    rewritePattern:
      /^\/uk\/blog\/jak-podniesc-ceny-w-salonie-kosmetycznym-i-nie-stracic-klientow/,
    rewriteReplaceString:
      '/uk/blog/yak-pidniaty-tsiny-v-saloni-krasy-i-ne-vtratyty-kliientiv',

    redirectPattern:
      /^\/uk\/blog\/jak-podniesc-ceny-w-salonie-kosmetycznym-i-nie-stracic-klientow/,
    redirectReplaceString:
      '/uk/blog/yak-pidniaty-tsiny-v-saloni-krasy-i-ne-vtratyty-kliientiv',
  },
  {
    rewritePattern:
      /^\/uk\/blog\/how-to-raise-prices-in-a-beauty-salon-and-not-lose-customers/,
    rewriteReplaceString:
      '/uk/blog/yak-pidniaty-tsiny-v-saloni-krasy-i-ne-vtratyty-kliientiv',

    redirectPattern:
      /^\/uk\/blog\/how-to-raise-prices-in-a-beauty-salon-and-not-lose-customers/,
    redirectReplaceString:
      '/uk/blog/yak-pidniaty-tsiny-v-saloni-krasy-i-ne-vtratyty-kliientiv',
  },

  // Blog reviews of beauty salon
  {
    rewritePattern: /^\/pl\/blog\/beauty-salon-reviews/,
    rewriteReplaceString: '/pl/blog/opinie-o-salonie-kosmetycznym',

    redirectPattern: /^\/pl\/blog\/beauty-salon-reviews/,
    redirectReplaceString: '/pl/blog/opinie-o-salonie-kosmetycznym',
  },
  {
    rewritePattern: /^\/pl\/blog\/vidhuky-pro-salon-krasy/,
    rewriteReplaceString: '/pl/blog/opinie-o-salonie-kosmetycznym',

    redirectPattern: /^\/pl\/blog\/vidhuky-pro-salon-krasy/,
    redirectReplaceString: '/pl/blog/opinie-o-salonie-kosmetycznym',
  },

  {
    rewritePattern: /^\/en\/blog\/opinie-o-salonie-kosmetycznym/,
    rewriteReplaceString: '/en/blog/beauty-salon-reviews',

    redirectPattern: /^\/en\/blog\/opinie-o-salonie-kosmetycznym/,
    redirectReplaceString: '/en/blog/beauty-salon-reviews',
  },
  {
    rewritePattern: /^\/en\/blog\/vidhuky-pro-salon-krasy/,
    rewriteReplaceString: '/en/blog/beauty-salon-reviews',

    redirectPattern: /^\/en\/blog\/vidhuky-pro-salon-krasy/,
    redirectReplaceString: '/en/blog/beauty-salon-reviews',
  },

  {
    rewritePattern: /^\/uk\/blog\/opinie-o-salonie-kosmetycznym/,
    rewriteReplaceString: '/uk/blog/vidhuky-pro-salon-krasy',

    redirectPattern: /^\/uk\/blog\/opinie-o-salonie-kosmetycznym/,
    redirectReplaceString: '/uk/blog/vidhuky-pro-salon-krasy',
  },
  {
    rewritePattern: /^\/uk\/blog\/beauty-salon-reviews/,
    rewriteReplaceString: '/uk/blog/vidhuky-pro-salon-krasy',

    redirectPattern: /^\/uk\/blog\/beauty-salon-reviews/,
    redirectReplaceString: '/uk/blog/vidhuky-pro-salon-krasy',
  },

  // Blog how to write a beauty salon business plan
  {
    rewritePattern: /^\/pl\/blog\/how-to-write-a-beauty-salon-business-plan/,
    rewriteReplaceString:
      '/pl/blog/jak-napisac-biznesplan-salonu-kosmetycznego',

    redirectPattern: /^\/pl\/blog\/how-to-write-a-beauty-salon-business-plan/,
    redirectReplaceString:
      '/pl/blog/jak-napisac-biznesplan-salonu-kosmetycznego',
  },
  {
    rewritePattern: /^\/pl\/blog\/yak-napysaty-biznes-plan-salonu-krasy/,
    rewriteReplaceString:
      '/pl/blog/jak-napisac-biznesplan-salonu-kosmetycznego',

    redirectPattern: /^\/pl\/blog\/yak-napysaty-biznes-plan-salonu-krasy/,
    redirectReplaceString:
      '/pl/blog/jak-napisac-biznesplan-salonu-kosmetycznego',
  },

  {
    rewritePattern: /^\/en\/blog\/jak-napisac-biznesplan-salonu-kosmetycznego/,
    rewriteReplaceString: '/en/blog/how-to-write-a-beauty-salon-business-plan',

    redirectPattern: /^\/en\/blog\/jak-napisac-biznesplan-salonu-kosmetycznego/,
    redirectReplaceString: '/en/blog/how-to-write-a-beauty-salon-business-plan',
  },
  {
    rewritePattern: /^\/en\/blog\/yak-napysaty-biznes-plan-salonu-krasy/,
    rewriteReplaceString: '/en/blog/how-to-write-a-beauty-salon-business-plan',

    redirectPattern: /^\/en\/blog\/yak-napysaty-biznes-plan-salonu-krasy/,
    redirectReplaceString: '/en/blog/how-to-write-a-beauty-salon-business-plan',
  },

  {
    rewritePattern: /^\/uk\/blog\/jak-napisac-biznesplan-salonu-kosmetycznego/,
    rewriteReplaceString: '/uk/blog/yak-napysaty-biznes-plan-salonu-krasy',

    redirectPattern: /^\/uk\/blog\/jak-napisac-biznesplan-salonu-kosmetycznego/,
    redirectReplaceString: '/uk/blog/yak-napysaty-biznes-plan-salonu-krasy',
  },
  {
    rewritePattern: /^\/uk\/blog\/how-to-write-a-beauty-salon-business-plan/,
    rewriteReplaceString: '/uk/blog/yak-napysaty-biznes-plan-salonu-krasy',

    redirectPattern: /^\/uk\/blog\/how-to-write-a-beauty-salon-business-plan/,
    redirectReplaceString: '/uk/blog/yak-napysaty-biznes-plan-salonu-krasy',
  },

  // Blog sanitary requirements for a beauty salon what does the sanitary inspectorate require
  {
    rewritePattern:
      /^\/pl\/blog\/sanitary-requirements-for-a-beauty-salon-what-does-the-sanitary-inspectorate-require/,
    rewriteReplaceString:
      '/pl/blog/wymogi-sanitarne-dla-salonu-kosmetycznego-czego-wymaga-sanepid',

    redirectPattern:
      /^\/pl\/blog\/sanitary-requirements-for-a-beauty-salon-what-does-the-sanitary-inspectorate-require/,
    redirectReplaceString:
      '/pl/blog/wymogi-sanitarne-dla-salonu-kosmetycznego-czego-wymaga-sanepid',
  },
  {
    rewritePattern:
      /^\/pl\/blog\/sanitarni-vymohy-do-salonu-krasy-scho-vymahaie-saninspektsiia/,
    rewriteReplaceString:
      '/pl/blog/wymogi-sanitarne-dla-salonu-kosmetycznego-czego-wymaga-sanepid',

    redirectPattern:
      /^\/pl\/blog\/sanitarni-vymohy-do-salonu-krasy-scho-vymahaie-saninspektsiia/,
    redirectReplaceString:
      '/pl/blog/wymogi-sanitarne-dla-salonu-kosmetycznego-czego-wymaga-sanepid',
  },

  {
    rewritePattern:
      /^\/en\/blog\/wymogi-sanitarne-dla-salonu-kosmetycznego-czego-wymaga-sanepid/,
    rewriteReplaceString:
      '/en/blog/sanitary-requirements-for-a-beauty-salon-what-does-the-sanitary-inspectorate-require',

    redirectPattern:
      /^\/en\/blog\/wymogi-sanitarne-dla-salonu-kosmetycznego-czego-wymaga-sanepid/,
    redirectReplaceString:
      '/en/blog/sanitary-requirements-for-a-beauty-salon-what-does-the-sanitary-inspectorate-require',
  },
  {
    rewritePattern:
      /^\/en\/blog\/sanitarni-vymohy-do-salonu-krasy-scho-vymahaie-saninspektsiia/,
    rewriteReplaceString:
      '/en/blog/sanitary-requirements-for-a-beauty-salon-what-does-the-sanitary-inspectorate-require',

    redirectPattern:
      /^\/en\/blog\/sanitarni-vymohy-do-salonu-krasy-scho-vymahaie-saninspektsiia/,
    redirectReplaceString:
      '/en/blog/sanitary-requirements-for-a-beauty-salon-what-does-the-sanitary-inspectorate-require',
  },

  {
    rewritePattern:
      /^\/uk\/blog\/wymogi-sanitarne-dla-salonu-kosmetycznego-czego-wymaga-sanepid/,
    rewriteReplaceString:
      '/uk/blog/sanitarni-vymohy-do-salonu-krasy-scho-vymahaie-saninspektsiia',

    redirectPattern:
      /^\/uk\/blog\/wymogi-sanitarne-dla-salonu-kosmetycznego-czego-wymaga-sanepid/,
    redirectReplaceString:
      '/uk/blog/sanitarni-vymohy-do-salonu-krasy-scho-vymahaie-saninspektsiia',
  },
  {
    rewritePattern:
      /^\/uk\/blog\/sanitary-requirements-for-a-beauty-salon-what-does-the-sanitary-inspectorate-require/,
    rewriteReplaceString:
      '/uk/blog/sanitarni-vymohy-do-salonu-krasy-scho-vymahaie-saninspektsiia',

    redirectPattern:
      /^\/uk\/blog\/sanitary-requirements-for-a-beauty-salon-what-does-the-sanitary-inspectorate-require/,
    redirectReplaceString:
      '/uk/blog/sanitarni-vymohy-do-salonu-krasy-scho-vymahaie-saninspektsiia',
  },

  // Blog how to write a beauty salon business plan
  {
    rewritePattern:
      /^\/pl\/blog\/effective-hair-salon-advertising-how-to-reach-customers/,
    rewriteReplaceString:
      '/pl/blog/skuteczna-reklama-salonu-fryzjerskiego-jak-trafic-do-klientow',

    redirectPattern:
      /^\/pl\/blog\/effective-hair-salon-advertising-how-to-reach-customers/,
    redirectReplaceString:
      '/pl/blog/skuteczna-reklama-salonu-fryzjerskiego-jak-trafic-do-klientow',
  },
  {
    rewritePattern:
      /^\/pl\/blog\/efektyvna-reklama-perukarni-yak-zaluchyty-kliientiv/,
    rewriteReplaceString:
      '/pl/blog/skuteczna-reklama-salonu-fryzjerskiego-jak-trafic-do-klientow',

    redirectPattern:
      /^\/pl\/blog\/efektyvna-reklama-perukarni-yak-zaluchyty-kliientiv/,
    redirectReplaceString:
      '/pl/blog/skuteczna-reklama-salonu-fryzjerskiego-jak-trafic-do-klientow',
  },

  {
    rewritePattern:
      /^\/en\/blog\/skuteczna-reklama-salonu-fryzjerskiego-jak-trafic-do-klientow/,
    rewriteReplaceString:
      '/en/blog/effective-hair-salon-advertising-how-to-reach-customers',

    redirectPattern:
      /^\/en\/blog\/skuteczna-reklama-salonu-fryzjerskiego-jak-trafic-do-klientow/,
    redirectReplaceString:
      '/en/blog/effective-hair-salon-advertising-how-to-reach-customers',
  },
  {
    rewritePattern:
      /^\/en\/blog\/efektyvna-reklama-perukarni-yak-zaluchyty-kliientiv/,
    rewriteReplaceString:
      '/en/blog/effective-hair-salon-advertising-how-to-reach-customers',

    redirectPattern:
      /^\/en\/blog\/efektyvna-reklama-perukarni-yak-zaluchyty-kliientiv/,
    redirectReplaceString:
      '/en/blog/effective-hair-salon-advertising-how-to-reach-customers',
  },

  {
    rewritePattern:
      /^\/uk\/blog\/skuteczna-reklama-salonu-fryzjerskiego-jak-trafic-do-klientow/,
    rewriteReplaceString:
      '/uk/blog/efektyvna-reklama-perukarni-yak-zaluchyty-kliientiv',

    redirectPattern:
      /^\/uk\/blog\/skuteczna-reklama-salonu-fryzjerskiego-jak-trafic-do-klientow/,
    redirectReplaceString:
      '/uk/blog/efektyvna-reklama-perukarni-yak-zaluchyty-kliientiv',
  },
  {
    rewritePattern:
      /^\/uk\/blog\/effective-hair-salon-advertising-how-to-reach-customers/,
    rewriteReplaceString:
      '/uk/blog/efektyvna-reklama-perukarni-yak-zaluchyty-kliientiv',

    redirectPattern:
      /^\/uk\/blog\/effective-hair-salon-advertising-how-to-reach-customers/,
    redirectReplaceString:
      '/uk/blog/efektyvna-reklama-perukarni-yak-zaluchyty-kliientiv',
  },

  // Blog how to conduct effective talks with customers
  {
    rewritePattern:
      /^\/pl\/blog\/how-to-conduct-effective-talks-with-customers-communication-and-customer-service-in-a-beauty-salon/,
    rewriteReplaceString:
      '/pl/blog/jak-prowadzic-skuteczne-rozmowy-z-klientami-komunikacja-i-obsluga-klienta-w-salonie-kosmetycznym',

    redirectPattern:
      /^\/pl\/blog\/how-to-conduct-effective-talks-with-customers-communication-and-customer-service-in-a-beauty-salon/,
    redirectReplaceString:
      '/pl/blog/jak-prowadzic-skuteczne-rozmowy-z-klientami-komunikacja-i-obsluga-klienta-w-salonie-kosmetycznym',
  },
  {
    rewritePattern:
      /^\/pl\/blog\/naskilky-efektyvno-vy-spilkuyetesya-z-kliyentamy-spilkuvannya-ta-obsluhovuvannya-kliyentiv-v-saloni-krasy/,
    rewriteReplaceString:
      '/pl/blog/jak-prowadzic-skuteczne-rozmowy-z-klientami-komunikacja-i-obsluga-klienta-w-salonie-kosmetycznym',

    redirectPattern:
      /^\/pl\/blog\/naskilky-efektyvno-vy-spilkuyetesya-z-kliyentamy-spilkuvannya-ta-obsluhovuvannya-kliyentiv-v-saloni-krasy/,
    redirectReplaceString:
      '/pl/blog/jak-prowadzic-skuteczne-rozmowy-z-klientami-komunikacja-i-obsluga-klienta-w-salonie-kosmetycznym',
  },

  {
    rewritePattern:
      /^\/en\/blog\/jak-prowadzic-skuteczne-rozmowy-z-klientami-komunikacja-i-obsluga-klienta-w-salonie-kosmetycznym/,
    rewriteReplaceString:
      '/en/blog/how-to-conduct-effective-talks-with-customers-communication-and-customer-service-in-a-beauty-salon',

    redirectPattern:
      /^\/en\/blog\/jak-prowadzic-skuteczne-rozmowy-z-klientami-komunikacja-i-obsluga-klienta-w-salonie-kosmetycznym/,
    redirectReplaceString:
      '/en/blog/how-to-conduct-effective-talks-with-customers-communication-and-customer-service-in-a-beauty-salon',
  },
  {
    rewritePattern:
      /^\/en\/blog\/naskilky-efektyvno-vy-spilkuyetesya-z-kliyentamy-spilkuvannya-ta-obsluhovuvannya-kliyentiv-v-saloni-krasy/,
    rewriteReplaceString:
      '/en/blog/how-to-conduct-effective-talks-with-customers-communication-and-customer-service-in-a-beauty-salon',

    redirectPattern:
      /^\/en\/blog\/naskilky-efektyvno-vy-spilkuyetesya-z-kliyentamy-spilkuvannya-ta-obsluhovuvannya-kliyentiv-v-saloni-krasy/,
    redirectReplaceString:
      '/en/blog/how-to-conduct-effective-talks-with-customers-communication-and-customer-service-in-a-beauty-salon',
  },

  {
    rewritePattern:
      /^\/uk\/blog\/jak-prowadzic-skuteczne-rozmowy-z-klientami-komunikacja-i-obsluga-klienta-w-salonie-kosmetycznym/,
    rewriteReplaceString:
      '/uk/blog/naskilky-efektyvno-vy-spilkuyetesya-z-kliyentamy-spilkuvannya-ta-obsluhovuvannya-kliyentiv-v-saloni-krasy',

    redirectPattern:
      /^\/uk\/blog\/jak-prowadzic-skuteczne-rozmowy-z-klientami-komunikacja-i-obsluga-klienta-w-salonie-kosmetycznym/,
    redirectReplaceString:
      '/uk/blog/naskilky-efektyvno-vy-spilkuyetesya-z-kliyentamy-spilkuvannya-ta-obsluhovuvannya-kliyentiv-v-saloni-krasy',
  },
  {
    rewritePattern:
      /^\/uk\/blog\/how-to-conduct-effective-talks-with-customers-communication-and-customer-service-in-a-beauty-salon/,
    rewriteReplaceString:
      '/uk/blog/naskilky-efektyvno-vy-spilkuyetesya-z-kliyentamy-spilkuvannya-ta-obsluhovuvannya-kliyentiv-v-saloni-krasy',

    redirectPattern:
      /^\/uk\/blog\/how-to-conduct-effective-talks-with-customers-communication-and-customer-service-in-a-beauty-salon/,
    redirectReplaceString:
      '/uk/blog/naskilky-efektyvno-vy-spilkuyetesya-z-kliyentamy-spilkuvannya-ta-obsluhovuvannya-kliyentiv-v-saloni-krasy',
  },

  // Blog - How to open a beauty salon - step by step guide for start-ups in the beauty industry
  {
    rewritePattern:
      /^\/pl\/blog\/how-to-open-a-beauty-salon-step-by-step-guide-for-start-ups-in-the-beauty-industry/,
    rewriteReplaceString:
      '/pl/blog/jak-otworzyc-salon-kosmetyczny-krok-po-kroku-poradnik-dla-poczatkujacych-przedsiebiorcow-w-branzy-beauty',

    redirectPattern:
      /^\/pl\/blog\/how-to-open-a-beauty-salon-step-by-step-guide-for-start-ups-in-the-beauty-industry/,
    redirectReplaceString:
      '/pl/blog/jak-otworzyc-salon-kosmetyczny-krok-po-kroku-poradnik-dla-poczatkujacych-przedsiebiorcow-w-branzy-beauty',
  },
  {
    rewritePattern:
      /^\/pl\/blog\/yak-vidkryty-salon-krasy-krok-za-krokom-posibnyk-dlya-startapiv-v-industriyi-krasy/,
    rewriteReplaceString:
      '/pl/blog/jak-otworzyc-salon-kosmetyczny-krok-po-kroku-poradnik-dla-poczatkujacych-przedsiebiorcow-w-branzy-beauty',

    redirectPattern:
      /^\/pl\/blog\/yak-vidkryty-salon-krasy-krok-za-krokom-posibnyk-dlya-startapiv-v-industriyi-krasy/,
    redirectReplaceString:
      '/pl/blog/jak-otworzyc-salon-kosmetyczny-krok-po-kroku-poradnik-dla-poczatkujacych-przedsiebiorcow-w-branzy-beauty',
  },

  {
    rewritePattern:
      /^\/en\/blog\/jak-otworzyc-salon-kosmetyczny-krok-po-kroku-poradnik-dla-poczatkujacych-przedsiebiorcow-w-branzy-beauty/,
    rewriteReplaceString:
      '/en/blog/how-to-open-a-beauty-salon-step-by-step-guide-for-start-ups-in-the-beauty-industry',

    redirectPattern:
      /^\/en\/blog\/jak-otworzyc-salon-kosmetyczny-krok-po-kroku-poradnik-dla-poczatkujacych-przedsiebiorcow-w-branzy-beauty/,
    redirectReplaceString:
      '/en/blog/how-to-open-a-beauty-salon-step-by-step-guide-for-start-ups-in-the-beauty-industry',
  },
  {
    rewritePattern:
      /^\/en\/blog\/yak-vidkryty-salon-krasy-krok-za-krokom-posibnyk-dlya-startapiv-v-industriyi-krasy/,
    rewriteReplaceString:
      '/en/blog/how-to-open-a-beauty-salon-step-by-step-guide-for-start-ups-in-the-beauty-industry',

    redirectPattern:
      /^\/en\/blog\/yak-vidkryty-salon-krasy-krok-za-krokom-posibnyk-dlya-startapiv-v-industriyi-krasy/,
    redirectReplaceString:
      '/en/blog/how-to-open-a-beauty-salon-step-by-step-guide-for-start-ups-in-the-beauty-industry',
  },

  {
    rewritePattern:
      /^\/uk\/blog\/jak-otworzyc-salon-kosmetyczny-krok-po-kroku-poradnik-dla-poczatkujacych-przedsiebiorcow-w-branzy-beauty/,
    rewriteReplaceString:
      '/uk/blog/yak-vidkryty-salon-krasy-krok-za-krokom-posibnyk-dlya-startapiv-v-industriyi-krasy',

    redirectPattern:
      /^\/uk\/blog\/jak-otworzyc-salon-kosmetyczny-krok-po-kroku-poradnik-dla-poczatkujacych-przedsiebiorcow-w-branzy-beauty/,
    redirectReplaceString:
      '/uk/blog/yak-vidkryty-salon-krasy-krok-za-krokom-posibnyk-dlya-startapiv-v-industriyi-krasy',
  },
  {
    rewritePattern:
      /^\/uk\/blog\/how-to-open-a-beauty-salon-step-by-step-guide-for-start-ups-in-the-beauty-industry/,
    rewriteReplaceString:
      '/uk/blog/yak-vidkryty-salon-krasy-krok-za-krokom-posibnyk-dlya-startapiv-v-industriyi-krasy',

    redirectPattern:
      /^\/uk\/blog\/how-to-open-a-beauty-salon-step-by-step-guide-for-start-ups-in-the-beauty-industry/,
    redirectReplaceString:
      '/uk/blog/yak-vidkryty-salon-krasy-krok-za-krokom-posibnyk-dlya-startapiv-v-industriyi-krasy',
  },
]
