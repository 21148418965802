import { Locales } from '@/types/locales'

export const YEAR_IN_SECONDS = 31536000
export const PAGE_REVALIDATE_TIME = 60
export const WEEKDAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]
export const DURATION_MULTIPLIER = 60
export const CONVERT_PRICE_MULTIPLIER = 100

export type ServiceCategoryColor = {
  primary: string
  secondary: string
  border: string
  primaryText: string
  secondaryText?: string
}

export const SERVICE_CATEGORY_COLORS: Record<string, ServiceCategoryColor> = {
  orange: {
    primary: 'bg-orange-200',
    secondary: 'bg-orange-50',
    border: 'border-orange-200',
    primaryText: 'text-orange-900',
  },
  amber: {
    primary: 'bg-amber-200',
    secondary: 'bg-amber-50',
    border: 'border-amber-200',
    primaryText: 'text-amber-900',
  },
  lime: {
    primary: 'bg-lime-200',
    secondary: 'bg-lime-50',
    border: 'border-lime-200',
    primaryText: 'text-lime-900',
  },
  teal: {
    primary: 'bg-teal-200',
    secondary: 'bg-teal-50',
    border: 'border-teal-200',
    primaryText: 'text-teal-900',
  },
  green: {
    primary: 'bg-green-200',
    secondary: 'bg-green-50',
    border: 'border-green-200',
    primaryText: 'text-green-900',
  },
  cyan: {
    primary: 'bg-cyan-200',
    secondary: 'bg-cyan-50',
    border: 'border-cyan-200',
    primaryText: 'text-cyan-900',
  },
  sky: {
    primary: 'bg-sky-200',
    secondary: 'bg-sky-50',
    border: 'border-sky-200',
    primaryText: 'text-sky-900',
  },
  blue: {
    primary: 'bg-blue-200',
    secondary: 'bg-blue-50',
    border: 'border-blue-200',
    primaryText: 'text-blue-900',
  },
  indigo: {
    primary: 'bg-indigo-200',
    secondary: 'bg-indigo-50',
    border: 'border-indigo-200',
    primaryText: 'text-indigo-900',
  },
  violet: {
    primary: 'bg-violet-200',
    secondary: 'bg-violet-50',
    border: 'border-violet-200',
    primaryText: 'text-violet-900',
  },
  purple: {
    primary: 'bg-purple-200',
    secondary: 'bg-purple-50',
    border: 'border-purple-200',
    primaryText: 'text-purple-900',
  },
  fuchsia: {
    primary: 'bg-fuchsia-200',
    secondary: 'bg-fuchsia-50',
    border: 'border-fuchsia-200',
    primaryText: 'text-fuchsia-900',
  },
}

export const LOCALES: Locales = ['pl', 'en', 'uk']
